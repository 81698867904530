<template>
    <div id="app" class="loginApp" style="background-image:url('/assets/images/dss.jpg');background-size:cover">
        <div class="loginAppShadow"></div>
        <v-container class="h-100vh">
            <!-- login OTP -->
            <v-dialog persistent v-model="otpDialog" width="350">
                <v-card style="justify-content: center; text-align: center">
                    <v-card-title class="font-weight-bold justify-center">
                        OTP
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <p>You will get a verification code to your registered number.
                        </p>
                        <ValidationObserver ref="form" class="w-100" lazy-validation v-slot="{ invalid }">
                            <ValidationProvider name="OTP" vid="otp" rules="required" v-slot="{ errors }">
                                <v-otp-input v-model="form.otp" :loading="loading" :error="errors[0] ? true : false"
                                    :error-messages="errors[0]" @finish="onFinish"></v-otp-input>
                            </ValidationProvider>
                        </ValidationObserver>
                        <p>Remaining time: {{ remainingTime }} sec</p>

                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- login OTP -->

            <div class="row d-flex loginBox justify-center align-center">
                <v-card elevation="12" class="d-flex align-center col-lg-8 col-12">
                    <v-row>
                        <v-col cols="6" class="loginLeft">
                            <div class="pa-5 ma-5 text-center ma-auto">
                                <v-img class="text-center ma-auto" max-height="100%" max-width="80px"
                                    position="center center" src="/assets/images/logo.png"></v-img>
                                <!-- <Logo class="ml-7" /> -->
                            </div>
                            <h3 class="text-center white--text">
                                Integrated Social Protection Management Information System
                                (ISPMIS)
                            </h3>
                            <div class="text-center orange--text my-4">
                                <h3>Notice Board</h3>
                            </div>
                            <p class=" orange--text mx-6" style="font-size: 14px;">
                                No One's ID and password is asked from the MIS admin of the
                                Department of Social Services (DSS) without solving your problem. Beware
                                of
                                cheaters. Please do not share your password with anyone and do
                                not
                                save it in your browser.
                            </p>
                        </v-col>
                        <v-col v-if="!resetPassword" cols="6">
                            <div class="d-flex flex-column align-center justify-center" style="height: 57vh">
                                <div class="text-center mb-2">
                                    <h3>Browser Registration</h3>
                                </div>
                                <ValidationObserver ref="form" class="w-100" lazy-validation v-slot="{ invalid }">
                                    <v-form @submit.prevent="submit" class="ma-5">
                                        <ValidationProvider name="Username" vid="username" rules="required"
                                            v-slot="{ errors }">
                                            <v-text-field label="Username" v-model="form.username"
                                                :error="errors[0] ? true : false" :error-messages="errors[0]"
                                                required></v-text-field>
                                        </ValidationProvider>
                                        <ValidationProvider name="Password" vid="password" rules="required"
                                            v-slot="{ errors }">
                                            <v-text-field type="password" label="Password" v-model="form.password" placeholder="Password"
                                                required :error="errors[0] ? true : false"
                                                :error-messages="errors[0]"></v-text-field>
                                        </ValidationProvider>
                                        <ValidationProvider name="Purpose of Use" vid="purpose_of_use" rules="required"
                                            v-slot="{ errors }">
                                            <v-textarea label="Purpose of Use" v-model="form.purpose_of_use"
                                                :error="errors[0] ? true : false" :error-messages="errors[0]" required
                                                rows="4" auto-grow></v-textarea>
                                        </ValidationProvider>

                                        <div class="d-inline d-flex justify-end">
                                            <v-btn color="blue" block type="submit" class="my-4 white--text"
                                                :loading="loading">
                                                Send Request
                                            </v-btn>
                                        </div>

                                        <div class="d-inline d-flex justify-end mb-2">
                                            <v-btn color="blue" @click="$router.push('/login')"
                                                class="font-sm white--text" block>
                                                Login
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </ValidationObserver>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <p class="text-center white--text position-relative">
              Copyright © {{ new Date().getFullYear() }}, DSS | All Rights Reserved
            </p>

        </v-container>
    </div>
</template>

<script>
import {
    getFingerprint
} from "../../plugins/fingerprint.js";
import {
    mapGetters
} from 'vuex'
import {
    mapState
} from "vuex";
import {
    extend,
    ValidationProvider,
    ValidationObserver
} from "vee-validate";
import {
    required
} from "vee-validate/dist/rules";
extend("required", required);
export default {
    name: "Device Token",
    title: "DSS - MIS - Device Token",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            resetPassword: false,
            remainingTime: 60,
            intervalId: null,
            errors: [],
            form: {
                username: "",
                purpose_of_use: "",
                password: "",
                device_type: "",  // Add device_type to the form
                device_ip: "",    // Add device_ip to the form
            },
            loading: false,
            otpDialog: false
        };
    },
    computed: {
        ...mapState({
            message: (state) => state.success_message,
        }),
        ...mapGetters(["getLoginresponse", "getOtpresponse"]),
    },
    async created() {
        const fingerprint = await getFingerprint();
        this.form.device_token = fingerprint;

        // Get device type
        this.form.device_type = this.getDeviceType();

        // Get device IP address
        this.form.device_ip = await this.getDeviceIp();
    },
    methods: {
        getDeviceType() {
            const ua = navigator.userAgent;

            // Detect Android Mobile
            if (/android/i.test(ua) && /mobile/i.test(ua)) return "Android Mobile";

            // Detect iOS devices (iPhone and iPad)
            if (/iPhone|iPod/.test(ua)) return "iOS Mobile";
            // if (/iPad/.test(ua)) return "iOS Tablet";

            // Detect Windows and MacOS laptops
            // if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(ua) && /Touch/.test(ua) === false) return "MacBook";
            if (/Windows/.test(ua) && /Touch/.test(ua) === false && /tablet/i.test(ua) === false) return "Laptop";

            // Detect Tablet devices
            // if (/tablet/i.test(ua)) return "Tablet";
            // if (/iPad|Android/.test(ua) && /Touch/.test(ua)) return "Tablet";

            // Default to Desktop
            return "Desktop";
        },
        async getDeviceIp() {
            try {
                const response = await this.$axios.get('/get-ip');
                return response.data.ip;
            } catch (error) {
                console.error("Failed to get IP address:", error);
                return "Unknown IP";
            }
        },
        submit() {
            console.log("🚀 ~ submit ~ this.form:", this.form);
            this.loading = true;
            this.$axios.post('/device-registration', this.form)
                .then(response => {
                    this.$toast.success("Device registration request sent successfully");
                })
                .catch(error => {
                    let errorMessage = error?.response?.data?.error || "There was an error submitting the form.";
                    this.$toast.error(errorMessage);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },

};
</script>

<style>
html.my-app,
body.my-app {
    overflow: hidden !important;
}

.h-100vh {
    height: 100vh;
}

.loginLeft {
    background: linear-gradient(180deg, rgba(43, 48, 75, 1) 54%, rgba(63, 43, 150, 1) 134%);
}

.loginBox {
    height: 100%;
}

.loginAppShadow {
    border-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(43, 43, 43, 0.6823529411764706);
    border: none;
    border-radius: 0px;
}

.position-relative {
    position: relative;
}

.w-100 {
    width: 100%;
}
</style>
