const PayrollManagementRoutes = [
  {
    path: "/payroll-management/approval-selection",
    name: "payment-payment",
    meta: {
      requiresAuth: true,
      permission: "approval-selection",
    },
    component: () =>
      import("../pages/PayrollManagement/ApprovalSelection/Index.vue"),
  },

  {
    path: "/payroll-management/payroll-setting",
    name: "payroll-setting",
    meta: {
      requiresAuth: true,
      permission: "payroll-setting-view",
    },
    component: () =>
      import("../pages/PayrollManagement/PayrollSetting/index.vue"),
  },

  {
    path: "/payroll-management/payroll-verification-setting",
    name: "payroll-setting",
    meta: {
      requiresAuth: true,
      permission: "payroll-setting-view",
    },
    component: () =>
      import(
        "../pages/PayrollManagement/PayrollSetting/PayrollVerificationSetting.vue"
        ),
  },

  //payment processor
  {
    path: "/payroll-management/payment-processor",
    name: "payment-processor",
    meta: {
      requiresAuth: true,
      permission: "payment-processor-create",
    },
    component: () =>
      import("../pages/PayrollManagement/paymentprocessor/Index.vue"),
  },

  {
    path: "/payroll-management/payment-processor-show/:id",
    name: "payment-processor-show",
    meta: {
      requiresAuth: true,
      permission: "payment-processor-edit",
    },
    component: () =>
      import("../pages/PayrollManagement/paymentprocessor/show.vue"),
  },

  {
    path: "/payroll-management/create",
    name: "payment-processor-show",
    meta: {
      requiresAuth: true,
      permission: "payment-processor-edit",
    },
    component: () =>
      import("../pages/PayrollManagement/paymentprocessor/show.vue"),
  },

  {
    path: "/payroll-management/payment-tracking",
    name: "payment-tracking",
    meta: {
      requiresAuth: true,
      permission: "payroll-payment-tracking",
    },
    component: () =>
      import("../pages/PayrollManagement/PaymentTracking/Index2.vue"),
  },

  {
    path: "/payroll-management/dashboard",
    name: "payroll-management-dashboard",
    meta: {
      requiresAuth: true,
      permission: "payroll-dashboard-show",
    },
    component: () => import("../pages/PayrollManagement/Dashboard/Index.vue"),
  },

  // {
  //   path: "/payroll-management/application-selection",
  //   name: "payment-payment",
  //   meta: {
  //     requiresAuth: true,
  //     permission: "payment-processor",
  //   },
  //   component: () => import("../pages/PayrollManagement/PaymentProcessor/Index.vue")
  // },

  {
    path: "/payroll-management/payroll-create",
    name: "payroll-create",
    meta: {
      requiresAuth: true,
      permission: "payroll-create",
    },
    component: () =>
      import("../pages/PayrollManagement/PayrollCreate/Index.vue"),
  },
  {
    path: "/payroll-management/payroll-approval",
    name: "payroll-list",
    meta: {
      requiresAuth: true,
      permission: "payroll-approval-view",
    },
    component: () =>
      import("../pages/PayrollManagement/PayrollApproval/Index.vue"),
  },
  {
    path: "/payroll-management/payroll/view/:id",
    name: "payroll-view",
    meta: {
      requiresAuth: true,
      permission: "payroll-view",
    },
    component: () =>
      import("../pages/PayrollManagement/PayrollApproval/View.vue"),
  },
  {
    path: "/payroll-management/payment-cycle",
    name: "payment-cycle",
    meta: {
      requiresAuth: true,
      permission: "payroll-payment-cycle-create",
    },
    component: () =>
      import("../pages/PayrollManagement/PaymentCycle/Index.vue"),
  },
  {
    path: "/payroll-payment/cycle/view/:id",
    name: "Payroll Payment view",
    meta: {
      requiresAuth: true,
      permission: "payroll-payment-cycle-show",
    },
    component: () =>
      import("../pages/PayrollManagement/PaymentCycle/viewDetails.vue"),
  },
  {
    path: "/payroll-payment/cycle/payroll-wise-benficiary/:cycle_id/:payroll_id",
    name: "Payroll Payment view",
    meta: {
      requiresAuth: true,
      permission: "payroll-payment-cycle-show",
    },
    component: () =>
      import(
        "../pages/PayrollManagement/PaymentCycle/payrollWiseBeneficiaryList.vue"
        ),
  },
  {
    path: "/payroll-payment/cycle/reject/:id",
    name: "payroll payment cycle reject",
    meta: {
      requiresAuth: true,
      permission: "payroll-payment-cycle-reject",
    },
    component: () =>
      import("../pages/PayrollManagement/PaymentCycle/RejectDetails.vue"),
  },
  {
    path: "/payroll-payment/cycle/payrolls/:id",
    name: "payroll-payment-cycle-reject",
    meta: {
      requiresAuth: true,
      permission: "payroll-payment-cycle-reject",
    },
    component: () =>
      import("../pages/PayrollManagement/PaymentCycle/payrolls.vue"),
  },
  //data pull
  {
    path: "/payroll-management/payroll-reconciliation-data-pull",
    name: "payroll-reconciliation-data-pull",
    meta: {
      requiresAuth: true,
      permission: "payroll-reconciliation-data-pull-view",
    },
    component: () =>
      import("../pages/PayrollManagement/ReconciliationDataPull/Index.vue"),
  },
  {
    path: "/payroll-management/reconciliation-cordination",
    name: "reconciliation-cordination",
    meta: {
      requiresAuth: true,
      permission: "payroll-reconciliation-cordination-create",
    },
    component: () =>
      import("../pages/PayrollManagement/ReconciliationCordination/Index.vue"),
  },
  {
    path: "/payroll-payment/reconciliation/edit/:id",
    name: "Payroll Reconciliation Edit",
    meta: {
      requiresAuth: true,
      permission: "payroll-reconciliation-cordination-edit",
    },
    component: () =>
      import("../pages/PayrollManagement/ReconciliationCordination/Edit.vue"),
  },
  // {
  //   path: "/payroll-management/supplimentary",
  //   name: "supplimentary-payroll",
  //   meta: {
  //     requiresAuth: true,
  //     permission: "supplimentary-payroll",
  //   },
  //   component: () =>
  //     import("../pages/PayrollManagement/SupplimentaryPayroll/Index.vue"),
  // },

  {
    path: "/payroll-management/supplementary-payroll",
    name: "supplementary-payroll-show",
    meta: {
      requiresAuth: true,
      permission: "supplementary-payroll-show",
    },
    component: () =>
      import("../pages/PayrollManagement/Supplementary/index.vue"),
  },
  {
    path: "/payroll-management/supplementary-payroll-show/:id",
    name: "supplementary-cycle-details",
    meta: {
      requiresAuth: true,
      permission: "supplementary-cycle-details",
    },
    component: () =>
      import("../pages/PayrollManagement/Supplementary/show.vue"),
  },

  {
    path: "/payroll-management/supplementary-beneficiary/:id",
    name: "supplementary-beneficiary-show",
    meta: {
      requiresAuth: true,
      permission: "supplementary-beneficiary-show",
    },
    component: () =>
      import(
        "../pages/PayrollManagement/Supplementary/beneficiary-details.vue"
        ),
  },
  //common bank ,branch
  {
    path: "/common/all-banks",
    name: "all-banks",
    meta: {
      requiresAuth: true,
      permission: "bank-information",
    },
    component: () => import("../pages/Common/bank/Index.vue"),
  },
  {
    path: "/common/all-mfs",
    name: "all-mfs",
    meta: {
      requiresAuth: true,
      permission: "mfs-information",
    },
    component: () => import("../pages/Common/mfs/Index.vue"),
  },
  {
    path: "/common/all-branch",
    name: "all-branch",
    meta: {
      requiresAuth: true,
      permission: "branch-information",
    },
    component: () => import("../pages/Common/branch/Index.vue"),
  },
];

export default PayrollManagementRoutes;
