<template>
    <div id="app" class="grand-opening" style="background: linear-gradient(to bottom, #00284d, #005bb5); color: white; text-align: center; height: 100vh; overflow: hidden;">
      <div class="curtain" :class="{ open: curtainOpen }">
        <!-- Curtain Front Content -->
        <div class="curtain-left"></div>
        <div class="curtain-right"></div>
        <div class="curtain-content" v-if="!curtainOpen">
          <v-img
            class="logo mb-4"
            max-width="120px"
            src="/assets/images/logo.png"
            alt="DSS Logo"
          ></v-img>
          <h2>সমাজসেবা অধিদপ্তরের</h2>
          <h3>ইন্টিগ্রেটেড সোশ্যাল প্রটেকশন ম্যানেজমেন্ট ইনফরমেশন সিস্টেম (আইএসপি এমআইএস)-এর</h3>
          <h1>শুভ উদ্বোধন</h1>
          <v-btn color="orange" class="my-4 white--text" @click="openCurtain">শুভ উদ্বোধন</v-btn>
        </div>
      </div>
      <!-- Post-Curtain Content -->
      <div class="post-curtain" v-if="curtainOpen">
        <v-img
          class="logo mb-4"
          max-width="120px"
          src="/assets/images/logo.png"
          alt="DSS Logo"
        ></v-img>
        <h2>সমাজসেবা অধিদপ্তরের</h2>
        <h3>ইন্টিগ্রেটেড সোশ্যাল প্রটেকশন ম্যানেজমেন্ট ইনফরমেশন সিস্টেম (আইএসপি এমআইএস)-এর</h3>
        <h1>
          শুভ উদ্বোধন করলেন শারমীন এস মুরশিদ, <br />
          মাননীয় উপদেষ্টা, সমাজকল্যাণ মন্ত্রণালয়
        </h1>
        <h3 class="countdown">{{ countdown }}</h3>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        curtainOpen: false,
        countdown: null,
      };
    },
    methods: {
      openCurtain() {
        this.curtainOpen = true; // Start the curtain animation
        setTimeout(() => {
          this.startCountdown(); // Start the countdown after animation
        }, 1000);
      },
      startCountdown() {
        this.countdown = 10;
        const interval = setInterval(() => {
          this.countdown -= 1;
          if (this.countdown === 0) {
            clearInterval(interval);
            this.redirectHome();
          }
        }, 1000);
      },
      redirectHome() {
        this.$router.push("/home");
      },
    },
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .grand-opening {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
  }
  
  .logo {
    display: block;
    margin: 0 auto;
  }
  
  .curtain {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .curtain-left,
  .curtain-right {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #00284d;
    z-index: 1;
    transition: transform 1s ease-in-out;
  }
  
  .curtain-left {
    left: 0;
  }
  
  .curtain-right {
    right: 0;
  }
  
  .curtain.open .curtain-left {
    transform: translateX(-100%);
  }
  
  .curtain.open .curtain-right {
    transform: translateX(100%);
  }
  
  .curtain-content {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  
  .post-curtain {
    position: absolute;
    width: 100%;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .countdown {
    font-size: 48px;
    margin-top: 20px;
    color: orange;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  </style>
  