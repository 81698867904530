<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :class="{'bg-gray-cursor-not-allowed': readonly}"
          v-model="formattedValue"
          placeholder="dd/mm/yy"
          :readonly="true"
          :outlined="outlined"
          v-bind="attrs"
          v-on="on"
          :hide-details="hideDetails" :error="error"
            :error-messages="errorMessages"
        ></v-text-field>
      </template>
      <v-date-picker v-if="!readonly"
        v-model="internalValue"
        @input="onInput"
      ></v-date-picker>
    </v-menu>
  </template>
  
  <script>
  export default {
    name: "Datepicker",
    props: {
      value: {
        type: String | null, // Accepts date string
        required: true,
      },
      displayFormat: {
        type: String,
        default: "DD/MM/YYYY", // Default display format (e.g., for display in the text field)
      },
      outputFormat: {
        type: String,
        default: "YYYY-MM-DD", // Default format emitted for output
      },
      hideDetails: {
        type: Boolean,
        default: true,
      },
      errorMessages: {
        type: String,
        default: '',
      },
      error: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        menu: false,
        internalValue: this.value, // Holds the selected date
      };
    },
    computed: {
      formattedValue() {
        // Format the internal value for display
        return this.formatDate(this.internalValue, this.displayFormat);
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
      },
    },
    methods: {
      onInput(val) {
        this.menu = false;
        // Emit the date in the specified output format
        const formattedOutput = this.formatDate(val, this.outputFormat);
        this.$emit("input", formattedOutput);
      },
      formatDate(date, format) {
        if (!date) return ""; // Handle empty date
  
        const parsedDate = new Date(date);
        
        // Simple date formatting logic (example for YYYY-MM-DD, can be extended for other formats)
        const yyyy = parsedDate.getFullYear();
        const mm = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0
        const dd = String(parsedDate.getDate()).padStart(2, "0");
  
        switch (format) {
          case "YYYY-MM-DD":
            return `${yyyy}-${mm}-${dd}`;
          case "DD/MM/YYYY":
            return `${dd}/${mm}/${yyyy}`;
          case "MM/DD/YYYY":
            return `${mm}/${dd}/${yyyy}`;
          default:
            return date; // If no format matches, return the raw date
        }
      },
    },
  };
  </script>
  
  <style scoped>
    .bg-gray-cursor-not-allowed{
    cursor: not-allowed !important;
    background-color: #f0f0f0;
    }
    .bg-gray-cursor-not-allowed ::v-deep input{
    cursor: not-allowed !important;
    }
  </style>
  