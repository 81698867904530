<template>
  <div id="application">

    <v-app-bar color="#1c3b68" fixed height="80" class="px-4" dense dark>
      <v-row align="center" no-gutters>
        <v-img class="p-3 mr-4" max-height="100%" max-width="60px" position="center center"
          src="/assets/images/logo.png"></v-img>
        <v-toolbar-title>{{ $t('container.application_selection.application.title_online_1') }}<br>{{
          $t('container.application_selection.application.title_online_2') }}
        </v-toolbar-title>
        <v-col>
          <!-- Empty column to create space between title and LocaleSwitcher -->
        </v-col>
        <v-col>
          <!-- Empty column to create space between title and LocaleSwitcher -->
        </v-col>
        <v-col>
          <!-- Empty column to create space between title and LocaleSwitcher -->
        </v-col>
        <v-col>
          <!-- Empty column to create space between title and LocaleSwitcher -->
        </v-col>
        <v-col>
          <!-- Empty column to create space between title and LocaleSwitcher -->
        </v-col>
        <v-col>
          <!-- Empty column to create space between title and LocaleSwitcher -->
        </v-col>

        <v-col>
          <v-row align="center" justify="end" no-gutters>
            <v-col>
              <!-- Adjust the styling of LocaleSwitcher as needed -->
              <LocaleSwitcher />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>


    <v-row class="mx-5 my-5 mt-10">
      <v-col class="mt-10" cols="10" offset="1">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <form @submit.prevent="submitApplicationCheck()">
            <v-card class="pa-5 px-10 mb-4">
              <p class="mt-5" style="font-size: 20px">
                {{ $t('container.application_selection.application.new_applicant') }}
                <!-- <span class="red--text">
                  {{ $t('container.application_selection.application.applicant_alert') }}


                </span> -->

              </p>
              <ValidationProvider name="program" vid="program" rules="required" v-slot="{ errors }">
                <label>{{ $t('container.application_selection.application.program') }} </label>
                <span style="margin-left: 4px; color: red">*</span>
                <v-select @change="getProgramName()" outlined :items="programs" :item-text="getItemText" item-value="id"
                  v-model="data.program_id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক প্রোগ্রাম নির্বাচন করুন '
                    : 'Please Select Program') : ''">
                </v-select>
              </ValidationProvider>
              <v-row class="d-flex align-center" v-if="programDetails?.dead_option_enable_disable == 1">
                <v-col cols="auto">
                  <v-radio-group v-model="data.deadOptional" @change="handleDeadChangeOption" row>
                    <v-radio :label="$t('container.application_selection.application.dead')" value="1"></v-radio>
                    <v-radio :label="$t('container.application_selection.application.injured')" value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <div v-if="data.program_id">
                <v-expansion-panels v-model="panel" multiple>
                  <!-- Applicant Verification -->
                  <v-expansion-panel>
                    <v-expansion-panel-header color="#1c3b68">
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                      <h3 class="white--text">{{
                        $t('container.application_selection.application.applicant_verification')
                      }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <ValidationProvider name="program" vid="verification_type" rules="required" v-slot="{ errors }">

                        <div class="validation-error validation_error_age_limit">
                          <v-radio-group v-model="data.verification_type" row>
                            {{ $t('container.application_selection.application.verification_type') }}
                            <span style="
                              margin-left: 4px;
                              margin-right: 4px;
                              color: red;
                            ">*</span>
                            <v-radio :disabled="status_code === 200 && data.verification_type == 2" :label="$t('container.application_selection.application.nid')" :value="1"></v-radio>
                            <v-radio :disabled="status_code === 200 && data.verification_type == 1" :label="$t('container.application_selection.application.brn')" :value="2"></v-radio>
                          </v-radio-group>
                        </div>
                      </ValidationProvider>

                      <v-row>
                          <v-col md="6" cols="12">
                            <ValidationProvider name="Number" vid="verification_number" rules="checkNumber||required"
                              v-slot="{ errors }">
                              <label>
                                <span v-if="data.verification_type == 1">
                                  {{ $t('container.application_selection.application.nid') }}
                                </span>
                                <span v-else-if="data.verification_type == 2">
                                  {{ $t('container.application_selection.application.brn') }}
                                </span>
                                <span v-else>
                                  {{ $t('container.application_selection.application.nid_brn') }}

                                </span>

                              </label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field @change="checkNum()" outlined :class="{'bg-gray-cursor-not-allowed': nidVerified}" :clearable="!nidVerified" :readonly="nidVerified"  v-model="data.verification_number"
                                class="mr-2" type="text" required :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক  ১০ বা ১৭ ডিজিটের সাথে যাচাইকরণ নম্বর লিখুন প্রদান করুন '
                                  : 'Please enter verification number with either 10 or 17 digit') : ''">
                              </v-text-field>
                            </ValidationProvider>
                            </v-col>
                            <v-col md="6" cols="12">
                            <label>{{ $t('container.application_selection.application.date_of_birth') }} </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <ValidationProvider name="Date of Birth" vid="date_of_birth" v-slot="{ errors }"
                              rules="required">
                              <!-- <v-text-field v-model="data.date_of_birth" :class="{'bg-gray-cursor-not-allowed': nidVerified}" :readonly="nidVerified" type="date" :value="formattedDate"
                                outlined></v-text-field> -->
                                <Datepicker v-model="data.date_of_birth" :readonly="nidVerified"></Datepicker>
                            </ValidationProvider>
                            </v-col>
                        </v-row>

                        <v-row v-if="!nidVerified">
                          <v-col md="6" cols="12" v-if="data.verification_type == 1">
                            <Captcha ref="nidCaptchaRef" @onCaptchaToken="onNidCaptchaToken" @onCaptchaValue="onNidCaptchaValue"></Captcha>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-btn v-if="data.verification_type == 1" @click="verifyCard()" elevation="2"
                              :disabled=" data.date_of_birth == null || data.verification_number == null || data.verification_type == null || data.verification_type == 2 || data.date_of_birth == '' || data.verification_number == '' || data.verification_type == '' || (data.verification_type == 1 && (nidCaptchaValue == null || nidCaptchaValue == ''))"
                              class="btn mt-2 " color="primary">{{ $t('container.application_selection.application.verify_nid') }}</v-btn>
                            <v-btn v-if="data.verification_type == 2" @click="verifyCardDob()" elevation="2" class="btn mt-10" color="primary">{{ language == "bn" ? "পরবর্তী ধাপ" : "Next Step" }}</v-btn>
                          </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Applicant Verification end -->



                  <!-- 3rd Expansion panel -->
                  <!-- Personal Information  -->

                  <v-expansion-panel v-if="status_code == 200">
                    <v-expansion-panel-header color="#1c3b68">
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                      <h3 class="white--text">{{ $t('container.application_selection.application.personal_info') }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <div class="pa-2 mb-4">
                        <v-row>
                          <v-col cols="6" lg="6">
                            <v-img :src="imageUrl" style="
                                    width: 200px;
                                    height: 200px;
                                    border: 1px solid #ccc;
                                  " class="mb-5" v-if="imageUrl"></v-img>
                            <v-img src="/assets/images/profile.png" v-if="!imageUrl" style="
                                    width: 150px;
                                    height: 150px;
                                    border: 1px solid #ccc;
                                  " class="mb-5"></v-img>

                            <label>{{ $t('container.application_selection.application.image') }} ({{
                              $t('container.application_selection.application.image_alert') }})</label>
                            <ValidationProvider v-slot="{ errors }" name="Image" vid="image">
                              <v-file-input outlined show-size counter
                                :placeholder="language == 'bn' ? 'ফাইল নির্বাচন করুন' : 'Choose File'"
                                prepend-outer-icon="mdi-camera" v-model="data.image" accept="image/*"
                                @change="previewImage" prepend-icon="" id="image"
                                :hide-details="errors[0] ? false : true">
                              </v-file-input>
                            </ValidationProvider>
                            <!-- <ValidationProvider v-slot="{ errors }" name="Image" rules="required" vid="image">
                              <v-file-input outlined show-size counter v-model="data.image" accept="image/*"
                                @change="previewImage" id="image">
                                <template v-slot:prepend-inner>
                                  <v-icon @click="triggerFileInput">mdi-camera</v-icon>
                                </template>
                              </v-file-input>
                            </ValidationProvider> -->

                          </v-col>
                          <v-col cols="6" align-self="end" lg="6">
                            <v-img :src="signUrl" style="
                                    width: 200px;
                                    height: 80px;
                                    border: 1px solid #ccc;
                                  " class="mb-5" v-if="signUrl"></v-img>
                            <v-img src="/assets/images/sign.png" v-if="!signUrl" style="
                                    width: 200px;
                                    height: 80px;
                                    border: 1px solid #ccc;
                                  " class="mb-5"></v-img>
                            <ValidationProvider v-slot="{ errors }" name="Signature" vid="signature">
                              <label>{{ $t('container.application_selection.application.signature') }} ({{
                                $t('container.application_selection.application.signature_alert') }})</label>
                              <v-file-input :placeholder="language == 'bn' ? 'ফাইল নির্বাচন করুন '
                                : 'Choose File'" outlined show-size counter prepend-outer-icon="mdi-camera"
                                v-model="data.signature" accept="image/*" @change="previewSign" id="signature"
                                prepend-icon="" :hide-details="errors[0] ? false : true"></v-file-input>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Name in Bangla" vid="name_bn" rules="required||bangla_character"
                              v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.name_bn') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field v-model="data.name_bn" outlined :class="{'bg-gray-cursor-not-allowed': nidVerified}" :readonly="nidVerified" :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ নাম (বাংলায়) প্রদান করুন '
                                  : 'Please enter Full Name (Bangla)') : ''"
                                :hide-details="errors[0] ? false : true"></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Name in English" vid="name_en" rules="required||english"
                              v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.name_en') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field v-model="data.name_en" outlined :class="{'bg-gray-cursor-not-allowed': nidVerified}" :readonly="nidVerified" :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ নাম (ইংরেজীতে) প্রদান করুন '
                                  : 'Please enter Full Name (English)') : ''" :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Father Name in Bangla" vid="father_name_bn"
                              rules="required||bangla_character" v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.father_name_bn') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field v-model="data.father_name_bn" outlined clearable
                                :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ বাবার নাম (বাংলায়) প্রদান করুন' : 'Please enter Father Name (Bangla)') : ''"
                                :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Father Name in English" vid="father_name_en"
                              rules="required||english" v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.father_name_en') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field v-model="data.father_name_en" outlined clearable
                                :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ বাবার নাম (ইংরেজীতে) প্রদান করুন '
                                  : 'Please enter Father Name (English)') : ''"
                                :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <div class="validation-error-mobile">
                              <ValidationProvider name="Mother Name in Bangla" vid="mother_name_bn"
                                rules="required||bangla_character" v-slot="{ errors }">
                                <label>{{ $t('container.application_selection.application.mother_name_bn') }}</label>
                                <span style="margin-left: 4px; color: red">*</span>
                                <v-text-field v-model="data.mother_name_bn" outlined clearable
                                  :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ মায়ের নাম (বাংলায়) প্রদান করুন' : 'Please enter Mother Name (Bangla)') : ''"
                                  :hide-details="errors[0] ? false : true">
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </v-col>


                          <v-col cols="6" lg="6">
                            <div class="validation-error_marital">

                              <ValidationProvider name="Mother Name in English" vid="mother_name_en" v-slot="{ errors }"
                                rules="required||english">
                                <label>{{ $t('container.application_selection.application.mother_name_en') }}</label>
                                <span style="margin-left: 4px; color: red">*</span>
                                <v-text-field v-model="data.mother_name_en" outlined clearable
                                  :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ মায়ের নাম(ইংরেজীতে) প্রদান করুন '
                                    : 'Please enter Mother Name (English)') : ''"
                                  :hide-details="errors[0] ? false : true">
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </v-col>

                          <v-col cols="6" lg="6">


                            <ValidationProvider rules="checkNumberMobile||required" name="Mobile Number" vid="mobile"
                              v-slot="{ errors }">
                              <label style="display: inline-block">{{
                                $t('container.application_selection.application.mobile') }} </label><span
                                style="margin-left: 4px; color: red">*</span>

                              <v-text-field v-model="data.mobile" outlined  clearable
                                :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক গ্রহণযোগ্য মোবাইল নাম্বার প্রদান করুন '
                                  : 'Please enter valid Mobile Number') : ''" :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>


                          </v-col>


                          <v-col cols="6" lg="6">
                            <!-- <div class="validation-error_marital"> -->
                            <ValidationProvider name="Marital Status" rules="required" vid="marital_status"
                              v-slot="{ errors }">

                              <label>{{ $t('container.system_config.allowance_program.marital_status') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-select v-model="data.marital_status" outlined clearable :items="marital_status"
                                item-value="id" :item-text="getItemValue" :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক বৈবাহিক অবস্থা নির্বাচন করুন '
                                  : 'Please Select Marital Status') : ''" :hide-details="errors[0] ? false : true">
                              </v-select>
                            </ValidationProvider>
                            <!-- </div> -->
                          </v-col>
                          <v-col cols="6" lg="6" v-if="data.marital_status == 'Married'">
                            <ValidationProvider name="Spouse Name in Bangla" vid="spouse_name_bn"
                              rules="required||bangla_character" v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.spouse_name_bn') }}</label>
                              <v-text-field v-model="data.spouse_name_bn" outlined clearable
                                :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ স্বামী বা স্ত্রী(বাংলায়) প্রদান করুন' : 'Please enter Spouse Name (Bangla)') : ''"
                                :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6" v-if="data.marital_status == 'Married'">
                            <ValidationProvider name="Spouse Name in English" vid="spouse_name_en" v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.spouse_name_en') }}</label>
                              <v-text-field v-model="data.spouse_name_en" outlined clearable
                                :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ স্বামী বা স্ত্রী নাম(ইংরেজীতে) প্রদান করুন '
                                  : 'Please enter Spouse Name (English)') : ''"
                                :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Religion" vid="religion" v-slot="{ errors }" rules="required">
                              <label>{{ $t('container.application_selection.application.religion') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>

                              <v-select v-model="data.religion" outlined :items="religion" item-value="id"
                                :item-text="getItemValue" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ধর্ম নির্বাচন করুন '
                                  : 'Please Select Religion') : ''" :hide-details="errors[0] ? false : true">
                              </v-select>
                            </ValidationProvider>
                          </v-col>


                          <v-col cols="6" lg="6">
                            <div class="validation_error_gender_type">
                              <ValidationProvider name="Nationality" vid="nationality" readonly v-slot="{ errors }"
                                rules="required">
                                <label>{{ $t('container.application_selection.application.nationality') }}</label>
                                <span style="margin-left: 4px; color: red">*</span>
                                <v-text-field class="bg-gray-cursor-not-allowed" readonly v-model="translatedNationality" outlined
                                  :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক জাতীয়তা প্রদান করুন '
                                    : 'Please enter Nationality') : ''" :hide-details="errors[0] ? false : true">
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <!-- <div class="validation_error_age_limit"> -->
                            <ValidationProvider name="Age" vid="age" v-slot="{ errors }" rules="required">
                              <label>{{ $t('container.application_selection.application.age') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field :class="{'bg-gray-cursor-not-allowed': nidVerified}" :readonly="nidVerified" v-model="data.age" outlined type="text"
                                :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক বয়স প্রদান করুন '
                                  : 'Please enter Age') : ''" :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                            <!-- </div> -->
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Gender" vid="gender" v-slot="{ errors }" rules="required">
                              <label>{{ $t('container.system_config.allowance_program.gender') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-select v-model="data.gender_id" :class="{'bg-gray-cursor-not-allowed': nidVerified}" :readonly="nidVerified" item-value="id" outlined :items="genders"
                                :item-text="getItemValue" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক লিঙ্গ নির্বাচন করুন '
                                  : 'Please Select Gender') : ''" :hide-details="errors[0] ? false : true">
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Education Status" vid="education_status" rules="required"
                              v-slot="{ errors }">
                              <label>{{ $t('container.application_selection.application.education_status') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-select v-model="data.education_status" :item-text="getItemTextEducationalStatus"
                                item-value="id" outlined :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক শিক্ষাগত অবস্থা নির্বাচন করুন '
                                  : 'Please Select Educational Status') : ''" :items="educationalStatus"
                                :hide-details="errors[0] ? false : true">
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Profession" vid="profession" v-slot="{ errors }" rules="required">
                              <label>{{ $t('container.application_selection.application.profession') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-select v-model="data.profession" outlined clearable :items="professionType"
                                :item-text="getItemValue" item-value="id" :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক পেশা নির্বাচন করুন '
                                  : 'Please Select Profession') : ''" :hide-details="errors[0] ? false : true">
                              </v-select>
                            </ValidationProvider>
                          </v-col>

                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Personal Information end-->
                  <!-- 2nd Expansion panel -->


                  <!-- 3rd Expansion panel -->
                  <!-- Contact Information -->
                  <v-expansion-panel class="mt-4" v-if="status_code == 200">
                    <v-expansion-panel-header color="#1c3b68">
                      <h3 class="white--text">{{ $t('container.application_selection.application.contact_info') }}</h3>
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">


                      <v-row>
                        <v-col cols="12" class="text-center">
                          <v-chip color="success" class="text-uppercase" label>{{
                            $t('container.application_selection.application.present_address') }}</v-chip>
                        </v-col>
                        <v-col lg="6" md="6" cols="12">
                          <ValidationProvider name="Division" vid="division" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block"> {{
                              $t('container.system_config.demo_graphic.division.division') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" @input="onChangeDivision($event)"
                              v-model="data.division_id" outlined :items="divisions" :item-text="getItemText"
                              item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক বিভাগ নির্বাচন করুন '
                                : 'Please Select Division') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col lg="6" md="6" cols="12">
                          <ValidationProvider name="District" vid="district" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.district.district') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" outlined v-model="data.district_id"
                              @input="onChangeDistrict($event)" :items="districts" :item-text="getItemText"
                              item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক জেলা নির্বাচন করুন '
                                : 'Please Select District') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6">
                          <ValidationProvider name="Location Type" vid="location_type" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.list.location_type') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select @input="LocationType($event)" v-model="data.location_type"
                              :hide-details="errors[0] ? false : true" outlined :items="locationType"
                              :item-text="getItemValue" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক এলাকা নির্বাচন করুন '
                                : 'Please Select Location Type') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 2" lg="6" md="6" cols="6">
                          <ValidationProvider name="Upazila" vid="thana_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.upazila') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.thana_id" outlined
                              @change="onChangeUpazila($event)" :items="thanas" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক উপজেলা নির্বাচন করুন '
                                : 'Please Select Upazila') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 2" lg="6" md="6" cols="6">
                          <label style="display: inline-block">{{
                            $t('container.system_config.demo_graphic.ward.subLocation_type') }}
                          </label>
                          <span style="margin-left: 4px; color: red">*</span>
                          <ValidationProvider name="Sub Location" vid="subLocationType" v-slot="{ errors }"
                            rules="required">
                            <v-select @input="onChangeSubLocationType($event)" v-model="data.sub_location_type" outlined
                              :items="subLocationType" :item-text="getItemValue" item-value="id"
                              :error="errors[0] ? true : false" :hide-details="errors[0] ? false : true"
                              class="no-arrow-icon" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সাব লোকেশন নির্বাচন করুন '
                                : 'Please Select Sub Location') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>

                        <v-col v-if="data.location_type == 2 && data.sub_location_type == 2" cols="6">
                          <ValidationProvider name="Union" vid="union_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.union')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.union_id" outlined
                              @input="onChangeUnion($event)" :items="unions" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ইউনিয়ন নির্বাচন করুন '
                                : 'Please Select Union') : ''"></v-select>

                          </ValidationProvider>
                        </v-col>

                        <v-col v-if="data.location_type == 2 && data.sub_location_type == 1" cols="6">
                          <ValidationProvider name="Pouroshava" vid="pouro_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.pouro')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.pouro_id"
                              @input="onChangePouro($event)" outlined :items="pouros" :item-text="getItemText"
                              item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক পৌরসভা নির্বাচন করুন '
                                : 'Please Select Pourashava') : ''"></v-select>
                          </ValidationProvider>


                        </v-col>
                        <v-col v-if="data.location_type == 3" lg="6" md="6" cols="6">
                          <ValidationProvider name="City" vid="city_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.city')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.city_id"
                              @change="onChangeCity($event)" outlined :items="cities" :item-text="getItemText"
                              item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সিটি কর্পোরেশন নির্বাচন করুন '
                                : 'Please Select City Corporation') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 3" lg="6" md="6" cols="6">
                          <ValidationProvider name="Thana" vid="city_thana_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.thana')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.city_thana_id" outlined
                              @input="OnChangeCityThana($event)" :items="city_thanas" :item-text="getItemText"
                              item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক থানা নির্বাচন করুন '
                                : 'Please Select Thana') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 1" lg="6" md="6" cols="6">
                          <ValidationProvider name="Thana" vid="district_pouro_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.dist_pouro') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.district_pouro_id"
                              @input="onChangeDistrictPouro" outlined :items="district_poros" :item-text="getItemText"
                              item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক জেলা পৌরসভা নির্বাচন করুন '
                                : 'Please Select District Pourashava') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 3" lg="6" md="6" cols="6">
                          <ValidationProvider name="Ward" vid="ward_id_city" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.ward_id_city" outlined
                              :items="wards_city" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 2 && data.sub_location_type == 2" lg="6" md="6" cols="6">
                          <ValidationProvider name="Ward" vid="ward_id_union" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.ward_id_union" outlined
                              :items="wards_upazila_union" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 2 && data.sub_location_type == 1" lg="6" md="6" cols="6">
                          <ValidationProvider name="Ward" vid="ward_id_union" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">Ward
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.ward_id_pouro" outlined
                              :items="wards_upazila_pouro" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.location_type == 1" lg="6" md="6" cols="6">
                          <ValidationProvider name="Ward" vid="ward_id_dist" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.ward_id_dist" outlined
                              :items="wards_dist" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''">
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6" lg="6">
                          <ValidationProvider name="Post Code" vid="post_code" rules="CheckPost||required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.post_code') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-text-field v-model="data.post_code" type="number" outlined clearable
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক গ্রহণযোগ্য পোস্ট কোড প্রদান করুন'
                                : 'Please enter valid Post Code') : ''">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>




                        <v-col cols="6" lg="6">
                          <ValidationProvider name="Village/House No.,
                                                        Road No., Block No, Section" vid="address" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.address') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-text-field v-model="data.address" outlined clearable :error="errors[0] ? true : false"
                              handleCheckboxChangsa :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক গ্রাম/বাড়ি নং, রোড নং, ব্লক নং, সেকশন প্রদান করুন'
                                : 'Please enter Village/House No., Road No., Block No, Section') : ''"
                              :hide-details="errors[0] ? false : true">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>


                      <v-row>
                        <v-col cols="12" class="text-center">
                          <v-chip color="success" class="text-uppercase" label>{{
                            $t('container.application_selection.application.permanent_address') }}</v-chip>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox v-model="checkbox"
                            :label="$t('container.application_selection.application.same_address')"
                            @change="handleCheckboxChange"></v-checkbox>
                        </v-col>

                        <v-col lg="6" md="6" cols="12">
                          <ValidationProvider name="Division" vid="permanent_division" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.division.division') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" @input="permanent_onChangeDivision($event)"
                              v-model="data.permanent_division_id" outlined :items="permanent_divisions"
                              :item-text="getItemText" item-value="id" required :error="errors[0] ? true : false"
                              :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক বিভাগ নির্বাচন করুন '
                                : 'Please Select Division') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col lg="6" md="6" cols="12">
                          <ValidationProvider name="District" vid="permanent_district" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.district.district') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" outlined
                              v-model="data.permanent_district_id" @input="permanent_onChangeDistrict($event)"
                              :items="permanent_districts" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক জেলা নির্বাচন করুন '
                                : 'Please Select District') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6">
                          <ValidationProvider name="Location Type" vid="permanent_location_type" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.list.location_type') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select @input="permanent_LocationType($event)" v-model="data.permanent_location_type"
                              :hide-details="errors[0] ? false : true" outlined :items="locationType"
                              :item-text="getItemValue" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক এলাকা নির্বাচন করুন '
                                : 'Please Select Location Type') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 2" lg="6" md="6" cols="12">
                          <ValidationProvider name="Upazila" vid="permanent_thana_id" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.upazila') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_thana_id" outlined
                              @change="permanent_onChangeUpazila($event)" :items="permanent_thanas"
                              :item-text="getItemText" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক উপজেলা নির্বাচন করুন '
                                : 'Please Select Upazila') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 2" cols="6">
                          <label style="display: inline-block"> {{
                            $t('container.system_config.demo_graphic.ward.subLocation_type') }}
                          </label>
                          <span style="margin-left: 4px; color: red">*</span>
                          <ValidationProvider name="Sub Location" vid="subLocationTypePermanent" v-slot="{ errors }"
                            rules="required">
                            <v-select @input="onChangeSubLocationTypePermanent($event)"
                              v-model="data.permanent_sub_location_type" outlined :items="subLocationType"
                              :item-text="getItemValue" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সাব লোকেশন নির্বাচন করুন '
                                : 'Please Select Sub Location') : ''" :hide-details="errors[0] ? false : true"
                              class="no-arrow-icon"></v-select>
                          </ValidationProvider>
                        </v-col>

                        <v-col v-if="data.permanent_location_type == 2 && data.permanent_sub_location_type == 2" lg="6"
                          md="6" cols="12">
                          <ValidationProvider name="Union" vid="permanent_union_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.union')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_union_id"
                              @input="permanent_onChangeUnion($event)" outlined :items="permanent_unions"
                              :item-text="getItemText" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ইউনিয়ন নির্বাচন করুন '
                                : 'Please Select Union') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 2 && data.permanent_sub_location_type == 1" lg="6"
                          md="6" cols="12">
                          <ValidationProvider name="Pouroshava" vid="permanent_pouro_id" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.pouro')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_pouro_id"
                              @input="Permanent_onChangePouro($event)" outlined :items="permanent_pouros"
                              item-text="name_en" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক পৌরসভা নির্বাচন করুন '
                                : 'Please Select Pourashava') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 3" lg="6" md="6" cols="12">
                          <ValidationProvider name="City" vid="permanent_city_id" rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.city')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_city_id"
                              @change="permanent_onChangeCity($event)" outlined :items="permanent_cities"
                              :item-text="getItemText" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সিটি কর্পোরেশন নির্বাচন করুন '
                                : 'Please Select City Corporation') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 3" lg="6" md="6" cols="12">
                          <ValidationProvider name="Thana" vid="permanent_city_thana_id" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.thana')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true"
                              @input="Permanent_OnChangeCityThana($event)" v-model="data.permanent_city_thana_id" outlined
                              :items="permanent_city_thanas" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক থানা নির্বাচন করুন '
                                : 'Please Select Thana') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 1" lg="6" md="6" cols="12">
                          <ValidationProvider name="District Pourashava" vid="permanent_district_pouro_id"
                            rules="required" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.dist_pouro') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" @input="Permanent_onChangeDistrictPouro"
                              v-model="data.permanent_district_pouro_id" outlined :items="permanent_district_poros"
                              :item-text="getItemText" item-value="id" :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক জেলা পৌরসভা নির্বাচন করুন '
                                : 'Please Select District Pourashava') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 3" lg="6" md="6" cols="12">
                          <ValidationProvider name="Ward" vid="permanent_ward_id_city" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_ward_id_city"
                              outlined :items="permanent_wards_city" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 1" lg="6" md="6" cols="12">
                          <ValidationProvider name="Ward" vid="permanent_ward_id_dist" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_ward_id_dist"
                              outlined :items="permanent_wards_dist" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 2 && data.permanent_sub_location_type == 2" lg="6"
                          md="6" cols="12">
                          <ValidationProvider name="Ward" vid="permanent_ward_id_upazila" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_ward_id_union"
                              outlined :items="permanent_wards_upazila_union" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="data.permanent_location_type == 2 && data.permanent_sub_location_type == 1" lg="6"
                          md="6" cols="12">
                          <ValidationProvider name="Ward" vid="permanent_ward_id_upazila" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{ $t('container.system_config.demo_graphic.ward.ward')
                            }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-select :hide-details="errors[0] ? false : true" v-model="data.permanent_ward_id_pouro"
                              outlined :items="permanent_wards_upazila_pouro" :item-text="getItemText" item-value="id"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ওয়ার্ড নির্বাচন করুন '
                                : 'Please Select Ward') : ''"></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6" lg="6">
                          <ValidationProvider name="Post Code" vid="permanent_post_code" rules="CheckPost||required"
                            type="number" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.post_code') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-text-field v-model="data.permanent_post_code" outlined clearable
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক গ্রহণযোগ্য পোস্ট কোড প্রদান করুন'
                                : 'Please enter valid Post Code') : ''">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="6" lg="6">
                          <ValidationProvider name="Village/House No.,
                                                Road No., Block No, Section" vid="permanent_address" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.system_config.demo_graphic.ward.address') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-text-field v-model="data.permanent_address" outlined clearable
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক গ্রাম/বাড়ি নং, রোড নং, ব্লক নং, সেকশন প্রদান করুন'
                                : 'Please enter Village/House No., Road No., Block No, Section') : ''"
                              :hide-details="errors[0] ? false : true">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Contact Information End -->
                  <!-- 4th Expansion panel -->
                  <!-- Information According to the Program -->

                  <v-expansion-panel class="ma-4" v-if="status_code == 200">
                    <v-expansion-panel-header color="#1c3b68">
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                      <h3 class="white--text">
                        {{ language == 'bn' ? programName.name_bn : programName.name_en }} {{
                          $t('container.application_selection.application.info') }}
                      </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <div v-if="programDetails.additional_field" class="py-2 ma-4">
                        <v-row>
                          <!-- <v-col cols="6" lg="6" v-if="programDetails?.is_disable_class == 1">
                            <template v-if="programDetails?.is_disable_class == 1">
                              <label>
                                {{ $t('container.system_config.allowance_program.class_wise_amount') }}

                                <span style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider v-slot="{ errors }">
                                <v-select :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" outlined
                                  v-model="data.lookup_type_id" :items="lookup_allowance_values" item-value="id"
                                  :item-text="getItemValue">
                                </v-select>
                              </ValidationProvider>
                            </template>
                          </v-col> -->
                          <v-col cols="6" lg="6"
                            v-if="programDetails.dead_option_enable_disable == 1 && data.dead_option_enable_disable == 0">
                            <template
                              v-if="programDetails.dead_option_enable_disable == 1 && data.dead_option_enable_disable == 0">
                              <label>
                                {{ $t('container.system_config.allowance_program.injury_type') }}

                                <span style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider v-slot="{ errors }">
                                <v-select :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" outlined
                                  v-model="data.injury_type" :items="injuryType" item-value="id"
                                  :item-text="getItemTextEducationalStatus">
                                </v-select>
                              </ValidationProvider>
                            </template>
                          </v-col>
                          <v-col v-for="(
                              fields, index
                            ) in programDetails.additional_field" :key="index" cols="6" lg="6">


                            <template v-if="fields.type == 'number'">
                              <v-row v-if="fields.verified == 1">
                                <v-col cols="8" lg="8">
                                  <label> {{ language == 'bn' ? fields.name_bn : fields.name_en }}
                                    <span v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                                  <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                    :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                    <v-text-field v-model="data.application_allowance_values[index]
                                      .value
                                      " :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                      :error-messages="errors[0] ? getErrorMessage(fields) : ''" type="number" outlined>
                                    </v-text-field>
                                  </ValidationProvider>

                                </v-col>
                                <v-col cols="4" lg="4" class="text-right">
                                  <v-btn class="btn mt-5" color="primary" style="height: 56px;" :disabled="data.application_allowance_values[index]
                                    .value == null">{{ $t('container.list.verify') }}</v-btn>

                                </v-col>
                              </v-row>
                              <template v-if="fields.verified == 0">
                                <label> {{ language == 'bn' ? fields.name_bn : fields.name_en }}
                                  <span v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                                <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                  :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                  <v-text-field v-model="data.application_allowance_values[index]
                                    .value
                                    " :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                    :error-messages="errors[0] ? getErrorMessage(fields) : ''" type="number" outlined>
                                  </v-text-field>
                                </ValidationProvider>

                              </template>




                            </template>
                            <template v-if="fields.type == 'dropdown'">
                              <label>

                                {{ language == 'bn' ? fields.name_bn : fields.name_en }}

                                <span v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                <v-select :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" outlined v-model="data.application_allowance_values[index]
                                    .allowance_program_additional_field_values_id
                                    " :items="fields.name_en == 'Class'
    ? classes
    : fields.additional_field_value
    " item-value="id" :item-text="fields.name_en == 'Class'
    ? 'value_en'
    : language == 'bn' ? 'value_bn' : 'value_en'
    ">
                                </v-select>
                              </ValidationProvider>
                            </template>
                            <template v-if="fields.type == 'checkbox'">
                              <label> {{ language == 'bn' ? fields.name_bn : fields.name_en }}<span v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                <v-select multiple :hide-details="errors[0] ? false : true"
                                  :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" outlined v-model="data.application_allowance_values[index]
                                    .allowance_program_additional_field_values_id
                                    " :items="fields.additional_field_value" item-value="id" item-text="value">
                                </v-select>
                              </ValidationProvider>
                            </template>
                            <template v-if="fields.type == 'file'">

                              <label> {{ language == 'bn' ? fields.name_bn : fields.name_en }}<span
                                  v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                <v-file-input :placeholder="language == 'bn' ? 'ফাইল নির্বাচন করুন '
                                  : 'Choose File'" v-model="data.application_allowance_values[index]
    .value
    " @change="addPreviewFile($event, index)" placeholder="Select your files" prepend-icon
                                  prepend-outer-icon="mdi-paperclip" outlined :hide-details="errors[0] ? false : true"
                                  :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" :show-size="1000">
                                </v-file-input>
                              </ValidationProvider>
                            </template>
                            <template v-if="fields.type == 'date'">
                              <label> {{ language == 'bn' ? fields.name_bn : fields.name_en }}<span
                                  v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                <!-- <v-text-field v-model="data.application_allowance_values[index]
                                  .value
                                  " :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" type="date" outlined> -->
                                  <Datepicker v-model="data.application_allowance_values[index]
                                  .value
                                  " :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''"></Datepicker>
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <template v-if="fields.type == 'disabled'">
                              <label>{{ fields.name_en
                              }}<span v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                <v-text-field v-model="data.application_allowance_values[index]
                                  .value
                                  " :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" disabled type="text"
                                  outlined>
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <template v-if="fields.type == 'text'">
                              <label>{{ language == 'bn' ? fields.name_bn : fields.name_en }}<span
                                  v-if="fields.option == 2" style="
                                    margin-left: 4px;
                                    margin-right: 4px;
                                    color: red;
                                  ">*</span></label>
                              <ValidationProvider :name="fields.name_en" :vid="'application_allowance_values' + index"
                                :rules="fields.option == 2 ? 'required' : ''" v-slot="{ errors }">
                                <v-text-field v-model="data.application_allowance_values[index]
                                  .value
                                  " :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(fields) : ''" type="text" outlined>
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Information According to the Program End -->
                  <!-- Expansion panel 5 start-->
                  <!-- Bank/MFS Information -->
                  <!-- If Bank/Mfs information checked in application program checkbox, the option below will open -->
                  <v-expansion-panel class="mb-4" v-if="programDetails?.is_bank_mfs_mandatory == 1 && status_code == 200" >
                    <v-expansion-panel-header color="#1c3b68">
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                      <h3 class="white--text">{{ $t('container.application_selection.application.bank') }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <v-row>

                       <v-col cols="12" md="12">
                          <v-radio-group v-model="data.account_type" row id="account_type">
                            <div class="d-flex align-center">
                              <label class="account-type-label">
                                {{ $t('container.application_selection.application.account_type') }}
                                <span class="required-label">*</span>
                              </label>
                              <v-radio

                                :label="$t('container.application_selection.application.bank_account')"
                                :value="1"
                              ></v-radio>
                              <v-radio
                                :label="$t('container.application_selection.application.mobile_account')"
                                :value="2"
                              ></v-radio>
                            </div>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="6" lg="6" v-if="data.account_type == 2">
                          <ValidationProvider name="Mobile Number Ownership" vid="account_owner" rules="required"
                            v-slot="{ errors }">
                            <label style="display: inline-block"> {{
                              $t('container.application_selection.application.mobile_ownership') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>

                            <v-select v-model="data.account_owner" outlined clearable :items="mobile_ownership"
                              :item-text="getItemValue" item-value="id" :error="errors[0] ? true : false"
                              :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক মোবাইল নম্বরের মালিকানা প্রদান করুন '
                                : 'Please enter Mobile Number ownership') : ''"
                              :hide-details="errors[0] ? false : true">
                            </v-select>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="6" lg="6" v-if="data.account_type == 2">
                          <ValidationProvider rules="required" name="MFS name" vid="mfs_name" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.application_selection.application.mfs_name') }} </label><span
                              style="margin-left: 4px; color: red">*</span>

                            <v-select v-model="data.mfs_name" @change="changeMfs" outlined clearable
                              :error="errors[0] ? true : false" :items="mfss" :item-text="getItemText"
                              item-value="id" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক এমএফএস নাম প্রদান করুন'
                                : 'Please enter MFS Name') : ''" :hide-details="errors[0] ? false : true">
                            </v-select>
                          </ValidationProvider>
                        </v-col>


                        <v-col cols="6" lg="6" v-if="data.account_type == 2">
                          <ValidationProvider rules="checkNumberMobile||required" name="Mobile Number"
                            vid="account_number" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.application_selection.application.mobile') }} </label><span
                              style="margin-left: 4px; color: red">*</span>

                            <v-text-field v-model="data.account_number" outlined  clearable
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক মোবাইল নম্বর প্রদান করুন '
                                : 'Please enter Mobile Number') : ''" :hide-details="errors[0] ? false : true">
                            </v-text-field>
                          </ValidationProvider>

                        </v-col>

                        <v-col cols="6" lg="6" v-if="data.account_type == 1">
                          <ValidationProvider rules="required" name="Bank name" vid="bank_name" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.application_selection.application.bank_name') }} </label><span
                              style="margin-left: 4px; color: red">*</span>

                            <v-select v-model="data.bank_name" outlined clearable :error="errors[0] ? true : false"
                              :items="banks" @change="getBranches(data.bank_name)" :item-text="getItemText"
                              item-value="id"
                              :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ব্যাংকের নাম প্রদান করুন' : 'Please enter Bank Name') : ''"
                              :hide-details="errors[0] ? false : true">
                            </v-select>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="6" lg="6" v-if="data.account_type == 1">
                          <ValidationProvider rules="required" name="Branch name" vid="branch_name" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.application_selection.application.branch_name') }}</label><span
                              style="margin-left: 4px; color: red">*</span>

                            <v-autocomplete v-model="data.branch_name" :error="!!errors[0]" :error-messages="errors[0]
                              ? language == 'bn'
                                ? 'অনুগ্রহ পূর্বক শাখার নাম প্রদান করুন'
                                : 'Please enter Branch Name'
                              : ''
                              " :hide-details="!errors[0]" :item-text="getItemText" :items="branches" clearable
                              item-value="id" outlined>
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6" lg="6" v-if="data.account_type == 1">
                          <ValidationProvider rules="required" name="Bank Account number" vid="bank_account_number"
                            v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.application_selection.application.bank_account_number') }} </label><span
                              style="margin-left: 4px; color: red">*</span>

                            <v-text-field v-model="data.account_number" outlined clearable
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ব্যাংক অ্যাকাউন্ট নম্বর প্রদান করুন '
                                : 'Please enter Bank Account Number') : ''" type="number"
                              :hide-details="errors[0] ? false : true">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6" lg="6" v-if="data.account_type == 1">
                          <ValidationProvider name="Account Name" :vid="'application_allowance_values' + index"
                            rules="required" v-slot="{ errors }">
                            <label style="display: inline-block"> {{
                              $t('container.application_selection.application.account_name') }}
                            </label>
                            <span style="margin-left: 4px; color: red">*</span>
                            <v-text-field class="bg-gray-cursor-not-allowed" v-model="data.account_name" outlined  :error="errors[0] ? true : false"
                              :error-messages="errors[0]" :hide-details="errors[0] ? false : true">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="6" lg="6">
                          <ValidationProvider name="Email" vid="Email" rules="email" v-slot="{ errors }">
                            <label style="display: inline-block">{{
                              $t('container.application_selection.application.email') }} </label>


                            <v-text-field v-model="data.email" outlined clearable type="email"
                              :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক গ্রহণযোগ্য ইমেইল প্রদান করুন '
                                : 'Please valid enter Email') : ''" :hide-details="errors[0] ? false : true">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <p class="red--text mt-2" v-if="data.account_type == 2">
                        {{ $t('container.application_selection.application.mobile_alert') }}

                      </p>
                      <p class="red--text mt-2" v-if="data.account_type == 1">
                        {{ $t('container.application_selection.application.bank_alert') }}

                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Bank/MFS Information End -->
                  <!-- Expansion panel 5 End -->
                  <!-- Nominee Information -->

                  <v-row v-if="programDetails?.is_nominee_optional == 1 && status_code == 200" class="d-flex align-center">
                    <v-col cols="auto">
                      <span>{{ $t('container.application_selection.application.is_nominnee_optional') }}</span>
                    </v-col>
                    <v-col cols="auto">
                      <v-radio-group v-model="checkboxNomineeOptional" @change="handleNomineeCheckboxChangeOptional" row>
                        <v-radio :label="$t('container.application_selection.application.yes')" value="1"></v-radio>
                        <v-radio :label="$t('container.application_selection.application.no')" value="0"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-expansion-panel class="mb-4" v-if="status_code == 200 && data.is_nominnee_optional == 1">

                    <v-expansion-panel-header color="#1c3b68">
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                      <h3 class="white--text"> {{ $t('container.application_selection.application.nominee_info') }}</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <div class="pa-2 mb-4">
                        <v-row>
<!--                          Nominee verification type-->
                          <v-col cols="12" lg="12">
                            <ValidationProvider name="program" vid="nominee_verification_type" rules="required" v-slot="{ errors }">

                              <div class="validation-error">
                                <v-radio-group v-model="data.nominee_verification_type" row>
                                  {{ $t('container.application_selection.application.nominee_verification_type') }}
                                  <span style="
                              margin-left: 4px;
                              margin-right: 4px;
                              color: red;
                            ">*</span>
                                  <v-radio :label="$t('container.application_selection.application.nid')" :value="1"></v-radio>
                                  <v-radio :label="$t('container.application_selection.application.brn')" :value="2"></v-radio>
                                </v-radio-group>
                              </div>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" lg="12">
                            <v-card class="validation_error_nominee">
                              <v-card-text>


                                <v-row>
                                  <v-col cols="12" md="6">

                                    <ValidationProvider name="National Identity (NID) / Birth Registration Number"
                                      rules="checkNumberNominee||required" vid="nominee_verification_number"
                                      v-slot="{ errors }" >

                                      <label>
                                        <span v-if="data.nominee_verification_type == 1">
                                          {{ $t('container.application_selection.application.nid') }}
                                        </span>
                                        <span v-else-if="data.nominee_verification_type == 2">
                                          {{ $t('container.application_selection.application.brn') }}
                                        </span>
                                        <span v-else>
                                          {{ $t('container.application_selection.application.nid_brn') }}
                                        </span>
                                      </label>

                                      <span style="margin-left: 4px; color: red">*</span>
                                      <v-text-field :class="{'bg-gray-cursor-not-allowed': nomineeNidVerified}" :readonly="nomineeNidVerified" v-model="data.nominee_verification_number" outlined
                                        @keyup="checkLengthAndVerify()" @change="checkNum()" clearable type="text"
                                        :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক  ১০ বা ১৭ ডিজিটের সাথে যাচাইকরণ নম্বর লিখুন প্রদান করুন '
                                          : 'Please enter verification number with either 10 or 17 digit') : ''"
                                        :hide-details="errors[0] ? false : true">
                                      </v-text-field>
                                    </ValidationProvider>
                                  </v-col>

                                  <v-col cols="12" md="6">

                                    <label>{{ $t('container.application_selection.application.date_of_birth') }}
                                    </label>
                                    <span style="margin-left: 4px; color: red">*</span>
                                    <ValidationProvider name=" Nominee Date of Birth" vid="nominee_date_of_birth"
                                      v-slot="{ errors }" rules="required">

                                      <!-- <v-text-field v-model="data.nominee_date_of_birth" :class="{'bg-gray-cursor-not-allowed': nomineeNidVerified}" :readonly="nomineeNidVerified" type="date"
                                        :value="formattedDateNominee" outlined
                                        :hide-details="errors[0] ? false : true"></v-text-field> -->
                                        <Datepicker v-model="data.nominee_date_of_birth" :readonly="nomineeNidVerified" :hide-details="errors[0] ? false : true"></Datepicker>
                                    </ValidationProvider>

                                  </v-col>
                                </v-row>



                                <v-row v-if="!nomineeNidVerified">
                                  <v-col cols="12" md="6" v-if="data.nominee_verification_type == 1">
                                    <Captcha ref="nomineeNidCaptchaRef" @onCaptchaToken="onNomineeNidCaptchaToken" @onCaptchaValue="onNomineeNidCaptchaValue"></Captcha>
                                  </v-col>
                                  <v-col cols="12" md="6" v-if="data.nominee_verification_type == 1">

                                    <v-btn @click="verifyNomineeCard()" elevation="2" id="verify-button"
                                      :disabled="data.nominee_verification_number == null || data.nominee_date_of_birth == '' || nomineeNidCaptchaValue == null || nomineeNidCaptchaValue == ''"
                                      class="btn mt-2 text-right" color="primary">{{ $t('container.application_selection.application.verify_nid')
                                      }}</v-btn>

                                  </v-col>
                                </v-row>

                              </v-card-text>
                            </v-card>

                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Nominee Name (BN)" vid="nominee_bn"
                              rules="required||bangla_character" v-slot="{ errors }">
                              <label style="display: inline-block">{{
                                $t('container.application_selection.application.name_bn') }}</label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field v-model="data.nominee_bn" outlined :class="{'bg-gray-cursor-not-allowed': nomineeNidVerified}" :readonly="nomineeNidVerified" :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ নাম (বাংলায়) প্রদান করুন' : 'Please enter Full Name (Bangla)') : ''"
                                :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Nominee Name (EN)" vid="nominee_en" rules="required"
                              v-slot="{ errors }">
                              <label style="display: inline-block">{{
                                $t('container.application_selection.application.name_en') }}
                              </label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field v-model="data.nominee_en" outlined :class="{'bg-gray-cursor-not-allowed': nomineeNidVerified}" :readonly="nomineeNidVerified" :error="errors[0] ? true : false"
                                :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ নাম (বাংলায়) প্রদান করুন '
                                  : 'Please enter Full Name (Bangla)') : ''" :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="6" lg="6">
                            <ValidationProvider name="Nominee Natinality" readonly vid="nominee_nationality"
                              v-slot="{ errors }" rules="required">
                              <label>{{ $t('container.application_selection.application.nationality') }} </label>
                              <span style="margin-left: 4px; color: red">*</span>
                              <v-text-field class="bg-gray-cursor-not-allowed" v-model="translatedNomineeNationality" outlined readonly
                                :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক সম্পূর্ণ নাম (ইংরেজীতে) প্রদান করুন '
                                  : 'Please enter Full Name (English)') : ''" :hide-details="errors[0] ? false : true">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <div class="validation_error_gender_type">
                              <ValidationProvider name="Relationship with beneficiary"
                                vid="nominee_relation_with_beneficiary" v-slot="{ errors }" rules="required">
                                <label>{{ $t('container.application_selection.application.relationship') }}</label>
                                <span style="margin-left: 4px; color: red">*</span>
                                <v-select v-model="data.nominee_relation_with_beneficiary" outlined item-value="id"
                                  :items="relations_with_bef" :item-text="getItemValue" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক নমিনীর সাথে সম্পর্ক প্রদান করুন '
                                    : 'Please enter Relationship with Nominee') : ''"
                                  :hide-details="errors[0] ? false : true">
                                </v-select>
                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="6" lg="6">
                            <v-img :src="nomineeImageUrl" style="
                                    width: 150px;
                                    height: 150px;
                                    border: 1px solid #ccc;
                                  " class="mb-5" v-if="nomineeImageUrl"></v-img>
                            <v-img src="/assets/images/profile.png" v-if="!nomineeImageUrl" style="
                                    width: 200px;
                                    height: 200px;
                                    border: 1px solid #ccc;
                                  " class="mb-5"></v-img>

                            <label>{{ $t('container.application_selection.application.image') }} ({{
                              $t('container.application_selection.application.image_alert') }})</label>
                            <ValidationProvider v-slot="{ errors }" name="Image" vid="nominee_image">
                              <v-file-input :placeholder="language == 'bn' ? 'ফাইল নির্বাচন করুন '
                                : 'Choose File'" outlined show-size counter prepend-outer-icon="mdi-camera"
                                v-model="data.nominee_image" accept="image/*" @change="previewImageNominee"
                                prepend-icon="" id="nominee_image"
                                :hide-details="errors[0] ? false : true"></v-file-input>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6" align-self="end" lg="6">
                            <v-img :src="nomineeSignUrl" style="
                                    width: 150px;
                                    height: 80px;
                                    border: 1px solid #ccc;
                                  " class="mb-5" v-if="nomineeSignUrl"></v-img>
                            <v-img src="/assets/images/sign.png" v-if="!nomineeSignUrl" style="
                                    width: 200px;
                                    height: 80px;
                                    border: 1px solid #ccc;
                                  " class="mb-5"></v-img>

                            <label>{{ $t('container.application_selection.application.signature') }} ({{
                              $t('container.application_selection.application.signature_alert') }})</label>
                            <ValidationProvider v-slot="{ errors }" name="Signature"
                              vid="nominee_signature">

                              <v-file-input outlined show-size counter prepend-outer-icon="mdi-camera"
                                v-model="data.nominee_signature" accept="image/*" @change="previewSignNominee"
                                :placeholder="language == 'bn' ? 'ফাইল নির্বাচন করুন' : 'Choose File'" prepend-icon=""
                                id="nominee_signature" :hide-details="errors[0] ? false : true"></v-file-input>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="12">
                            <v-checkbox v-model="checkboxNomineeAddress" @change="handleNomineeCheckboxChange"
                              :label="$t('container.application_selection.application.same_address')"></v-checkbox>
                          </v-col>
                          <v-col cols="12" lg="12">
                            <ValidationProvider name="Address of Nominee" vid="nominee_address" v-slot="{ errors }"
                              rules="required">
                              <label>{{ $t('container.application_selection.application.nominee_address') }}</label>
                              <v-textarea v-model="data.nominee_address" outlined clearable
                                :error="errors[0] ? true : false" :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ঠিকানা প্রদান করুন '
                                  : 'Please enter Address') : ''"
                                :hide-details="errors[0] ? false : true"></v-textarea>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Nominee Information End -->
                  <!-- 5th Expansion panel -->
                  <!-- Other Information of Eligibility -->
                  <v-expansion-panel class="mb-4" v-if="pmt_status == 1 && status_code == 200">
                    <v-expansion-panel-header color="#1c3b68">
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                      <h3 class="white--text">
                        {{ $t('container.application_selection.application.eligiblity_info') }}
                      </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <div class="pa-2 mb-4">
                        <v-row>
                          <v-col v-for="(variables, indexPMT) in PMTVariables" cols="6" lg="6" :key='indexPMT'>
                            <template v-if="variables.children.length == 0">
                              <label>
                                <!-- {{ variables.name_en }} -->
                                {{ language == 'bn' ? variables.name_bn : variables.name_en }}
                                <span style="
                                  margin-left: 4px;
                                  margin-right: 4px;
                                  color: red;
                                ">*</span></label>
                              <ValidationProvider :name="variables.name_en" :vid="'application_pmt_' + indexPMT"
                                rules="required" v-slot="{ errors }">
                                <v-select :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(variables) : ''" outlined required
                                  v-model="data.application_pmt[indexPMT].sub_variables" :items="[
                                    {
                                      id: variables.id,
                                      name_en: 'Yes',
                                      name_bn: 'হ্যাঁ',
                                    },
                                    {
                                      id: 0,
                                      name_en: 'No',
                                      name_bn: 'না',
                                    },
                                  ]" item-value="id" :item-text="getItemText">
                                </v-select>
                              </ValidationProvider>
                            </template>
                            <template v-else-if="variables.field_type == 1">
                              <label> {{ language == 'bn' ? variables.name_bn : variables.name_en }}
                                <span style="
                                  margin-left: 4px;
                                  margin-right: 4px;
                                  color: red;
                                ">*</span></label>
                              <ValidationProvider :name="variables.name_en" :vid="'application_pmt_' + indexPMT"
                                rules="required" v-slot="{ errors }">
                                <v-select :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(variables) : ''" outlined
                                  v-model="data.application_pmt[indexPMT].sub_variables" :items="variables.children"
                                  item-value="id" :item-text="getItemText" @change="onChangeHouse($event, variables)">
                                </v-select>
                              </ValidationProvider>
                            </template>

                            <template v-else-if="variables.field_type == 2">
                              <label> {{ language == 'bn' ? variables.name_bn : variables.name_en }}
                                <span style="
                                  margin-left: 4px;
                                  margin-right: 4px;
                                  color: red;
                                ">*</span></label>
                              <ValidationProvider :name="variables.name_en" :vid="'application_pmt_' + indexPMT"
                                rules="required" v-slot="{ errors }">
                                <v-select multiple :hide-details="errors[0] ? false : true"
                                  :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? getErrorMessage(variables) : ''" outlined
                                  v-model="data.application_pmt[indexPMT].sub_variables" :items="variables.children"
                                  item-value="id" :item-text="getItemText">
                                </v-select>
                              </ValidationProvider>
                            </template>

                          </v-col>
                          <v-col cols="6">
                            <template>
                              <label> {{ language == 'bn' ? 'ঘরের সংখ্যা' : 'No of Room' }}
                                <span style="
                                  margin-left: 4px;
                                  margin-right: 4px;
                                  color: red;
                                ">*</span></label>
                              <ValidationProvider name="No of Room" vid="no_of_room" rules="required" v-slot="{ errors }">
                                <v-select :hide-details="errors[0] ? false : true" :error="errors[0] ? true : false"
                                  :error-messages="errors[0] ? (language == 'bn' ? 'অনুগ্রহ পূর্বক ঘরের সংখ্যা প্রদান করুন '
                                    : 'Please enter No of Room') : ''" outlined v-model="data.no_of_room"
                                  :items="no_of_rooms" item-value="name_en" :item-text="getItemText"
                                  @change="onChange($event)">
                                </v-select>
                              </ValidationProvider>
                            </template>

                          </v-col>


                        </v-row>


                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Other Information of Eligibility end-->

                </v-expansion-panels>
              </div>
              <br>
              <div class="d-inline d-flex justify-end">
                <!-- <v-btn @click="resetForm()" elevation="2" class="btn mr-2" color="info">{{ $t('container.list.cancel')
                }}</v-btn> -->
                <!-- old one -->

                <v-btn @click="resetForm()" elevation="2" class="btn mr-2" outlined color="red" dark>{{
                  $t('container.list.cancel') }}</v-btn>
                <v-btn @click="submitApplicationCheck()" flat color="primary" :loading="loading"
                  class="custom-btn-width black white--text py-2">
                  {{ $t('container.list.submit') }}
                </v-btn>
                <!--:disabled="invalid"  -->
                <!-- <v-btn @click="submitApplication()" flat color="primary" :loading="loading"
                  class="custom-btn-width black white--text py-2">
                  {{ $t('container.list.preview') }}
                </v-btn> -->


              </div>
              <p class="red--text mt-5">
                {{ $t('container.application_selection.application.alert') }}
                *
              </p>
            </v-card>

          </form>
        </ValidationObserver>
        <!-- confirm modal  -->
        <!-- <v-dialog v-model="confirmDialog" width="350">
          <v-card style="justify-content: center; text-align: center">
          
            <v-card-text>
              <div class="subtitle-1 font-weight-medium mt-5">
              Do you want to submit the application ?
              </div>
            </v-card-text>
            <v-card-actions style="display: block">
              <v-row class="mx-0 my-0 py-2" justify="center">
                <v-btn text @click="confirmDialog = false" outlined class="custom-btn-width py-2 mr-10">
                    {{ $t("container.list.confirm") }}
                  </v-btn>
                <v-btn text @click="confirmDialog = false" outlined class="custom-btn-width py-2 mr-10">
                  {{ $t("container.list.cancel") }}
                </v-btn>

              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <template>
          <v-dialog v-model="confirmDialog" max-width="700" max-height="500">
            <v-card>
              <v-card-title class="font-weight-bold justify-center">

                {{ $t('container.application_selection.application.form_submission') }}
              </v-card-title>


              <!-- Add a divider after the title -->
              <v-divider></v-divider>

              <v-card-text class="text-center">
                <div class="subtitle-1 font-weight-medium mt-5">
                  {{ $t('container.application_selection.application.question') }}
                </div>
              </v-card-text>

              <v-card-actions class="d-flex justify-center">
                <v-btn text @click="confirmDialog = false" outlined class="custom-btn-width">
                  {{ $t("container.list.cancel") }}
                </v-btn>
                <!-- <v-btn text flat color="primary" :loading="loading" type="submit"
                  class="custom-btn-width success white--text py-2">
                  {{ $t("container.list.confirm") }}
                </v-btn> -->
                <v-btn @click="submitApplication()" flat color="primary" :loading="loading" type="submit"
                  class="custom-btn-width black white--text py-2">
                  {{ $t('container.list.confirm') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <!-- delete modal  -->

      </v-col>
    </v-row>

    <FooterBar />
  </div>
</template>

<script>
import Captcha from "@/components/Common/Captcha.vue";
import Datepicker from "@/components/Common/Datepicker.vue";
import FooterBar from "@/components/Common/FooterBar.vue";
import LocaleSwitcher from "@/components/Common/LocaleSwitcher";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { numeric } from 'vee-validate/dist/rules';

extend("numeric", {
  ...numeric,
  message: "This field must be a number"
});
extend('email', {
  validate: value => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  },
  message: 'Please enter a valid email address'
});
extend('english', {
  validate: value => {
    // Regular expression to match English characters, spaces, and specific additional characters
    const englishRegex = /^[a-zA-Z\s\.\:\(\)\-]+$/;

    // Check if value matches English characters, spaces, and specific additional characters
    return englishRegex.test(value);
  },
  message: 'Only English characters, spaces, and ( . : () - ) are allowed in this field'
});

extend('bangla_character', {
  validate: (value) => {
    // Updated regex to allow only Bangla letters, spaces, dots, colons, hyphens, or parentheses
    const banglaRegex = /^[\u0980-\u09FF\s\.\:\(\)\-]+$/;
    return banglaRegex.test(value);
  },
  message: "Please enter Bangla letters, spaces, dots, colons, hyphens, or parentheses in this field. English letters and Bangla numbers are not allowed.",
});
extend("checkNumber", {
  validate: (value) => {
    if (!value && value !== 0) {
      return false;
    }
    // Check if all characters are numeric and not allow special characters
    const isNumeric = /^[0-9]+$/.test(value);

    // Check if the length is either 10 or 17 characters
    const isCorrectLength = value.length == 10 || value.length == 17;

    // Return true if both conditions are met
    return isNumeric && isCorrectLength;
  },
  message: "This is required field and field must be a number with either 10 or 17 characters",
});
extend("checkNumberNominee", {
  validate: (value) => {
    if (!value && value !== 0) {
      return false;
    }
    // Check if all characters are numeric and not allow special characters
    const isNumeric = /^[0-9]+$/.test(value);

    // Check if the length is either 10 or 17 characters
    const isCorrectLength = value.length == 10 || value.length == 17;


    // Return false if value is the same as data.verfication_number

    // Return true if both conditions are met
    return isNumeric && isCorrectLength;
  },
  message: "This is required field and field must be a number with either 10 or 17 characters",
});
extend("CheckPost", {
  validate: (value) => {
    if (!value && value !== 0) {
      return false;
    }
    // Regex to match both English and Bangla digits
    const isNumeric = /^[0-9০-৯]+$/.test(value);

    // Check if the length is exactly 4
    const isCorrectLength = value.length == 4;

    // Return true if both conditions are met
    return isNumeric && isCorrectLength;
  },
  message: "Please provide 4 digit post code",
});
extend("checkMobileNumber", {
  validate: (value) => {
    if (!value && value !== 0) {
      return false;
    }
    // Check if all characters are numeric and not allow special characters
    const isNumeric = value.length == 8;

    return isNumeric;
  },
  message: "This field must be a number with 8 characters",
});
extend("checkNumberMobile", {
  validate: (value) => {
   // Regex to match both English and Bangla digits
    const isNumeric = /^[0-9০-৯]+$/.test(value);

    // Check if length is exactly 11
    const hasCorrectLength = value.length == 11;

    // Check if the value starts with "01" or "০১"
    const startsWithCorrectPrefix = value.startsWith("01") || value.startsWith("০১");

    return isNumeric && hasCorrectLength && startsWithCorrectPrefix;
  },
  message: "This field must be an 11-digit number starting with the correct format in Bangla or English.",
});

export default {
  title: "DSS - MIS - Online Application",

  data() {
    return {
      nidVerified: false,
      nomineeNidVerified: false,
      nidCaptchaToken: null,
      nidCaptchaValue: null,
      nomineeNidCaptchaToken: null,
      nomineeNidCaptchaValue: null,
      educationalStatus: [],
      injuryType: [],
      lookup_allowance_values: [],
      panel2Open: true,
      panel: [0, 1, 2, 3, 4, 5, 6],
      programs: [],
      mobile_operators: [],
      processors: [],
      processors_name: [],
      classes: [],
      loading: false,
      divisions: [],
      districts: [],
      thanas: [],
      cities: [],
      unions: [],
      district_poros: [],
      city_thanas: [],
      wards_city: [],
      wards_upazila: [],
      wards_dist: [],
      locationType: [],
      bank_names: [],
      mfs_names: [],
      banks: [],
      mfss: [],
      branchs: [],
      //   this.banks = result?.data?.bank
      // this.branchs = result?.data?.branch
      // this.mfses = result?.data?.mfs
      subLocationType: [
        {
          id: 1,
          value_en: "Pouroshava",
          value_bn: "পৌরসভা",
        },

        {
          id: 2,
          value_en: "Union",
          value_bn: "ইউনিয়ন ",
        },
      ],

      permanent_divisions: [],
      permanent_districts: [],
      permanent_thanas: [],
      permanent_cities: [],
      permanent_unions: [],
      permanent_pouros: [],
      permanent_district_poros: [],
      permanent_city_thanas: [],
      permanent_locationType: [],
      permanent_wards_city: [],
      permanent_wards_union: [],
      permanent_wards_pouro: [],
      permanent_wards_dist: [],

      education_status: [
      
      ],
      relations_with_bef: [],
      govt_programs: [
        "Old Age Allowance Program",
        "Disability Allowance Program",
        "Widow And Husband Adopted Allowance program",
        "Freedom Fighter Honorary Allowance",
        "No Allowance",
        "Other (specify)",
      ],
      marital_status: [ ],
      health_status: [
        "Totally Disabled",
        "Sick",
        "Insane",
        "Disabled",
        "Partially Powerless",
        "Other (specify)",
      ],

      financial_status: ["Poor", "Refugee", "Landless"],
      social_status: ["Widow", "Widower", "Divorced"],
      house_status: ["Homeless", "Self", "Rent", "Others"],
      land_ownership: [
        "Habitatless",
        "Below 0.5 acre",
        "Up to 1 acre",
        "Above 1 acre",
        "Other (specify)",
      ],

      mobile_ownership:[],
      religion: [],


      yes_no: ["Yes ", "No"],

      // relations_with_bef: [
      //   { name_en: 'Spouse', name_bn: 'স্বামী/স্ত্রী' },
      //   { name_en: 'Family member', name_bn: 'পরিবারের সদস্য' },
      //   { name_en: 'Close relative', name_bn: 'নিকট আত্মীয়' },
      //   { name_en: 'Parent', name_bn: 'পিতা/মাতা' },
      // ],
      status_code: null,
      status_code_nominee: null,
      activePicker: null,
      date: null,
      menu: false,
      isChecked: false,
      deleteDialog: false,
      confirmDialog: false,
      showAlert: false,
      no_of_rooms: [{ name_en: 1, name_bn: '১' },
      { name_en: 2, name_bn: '২' },
      { name_en: 3, name_bn: '৩' },
      { name_en: 4, name_bn: '৪' },
      { name_en: 5, name_bn: '৫' },
      { name_en: 6, name_bn: '৬' },
      { name_en: 7, name_bn: '৭' },
      { name_en: 8, name_bn: '৮' },
      { name_en: 9, name_bn: '৯' },
      { name_en: 10, name_bn: '১০' },
      { name_en: 11, name_bn: '১১' },
      { name_en: 12, name_bn: '১২' },
      { name_en: 13, name_bn: '১৩' },
      { name_en: 14, name_bn: '১৪' },
      { name_en: 15, name_bn: '১৫' },
      { name_en: 16, name_bn: '১৬' },
      { name_en: 17, name_bn: '১৭' },
      { name_en: 18, name_bn: '১৮' },
      { name_en: 19, name_bn: '১৯' },
      { name_en: 20, name_bn: '২০' }],
      bank_status: null,
      mfs_status: null,
      mfs_names_processor: [],

      data: {
        deadOptional: null,
        is_nominnee_optional: true,
        is_bank_mfs_mandatory: null,
        dead_option_enable_disable: null,
        lookup_type_id: null,
        injury_type: null,
        house_size: null,
        per_room_score: null,
        no_of_people_score: null,
        no_of_room: null,

        program_id: null,
        verification_type: 1,
        nominee_verification_type: 1,
        verification_number: null,
        account_type: null,
        age: null,
        date_of_birth: null,
        name_en: null,
        name_bn: null,
        father_name_en: null,
        father_name_bn: null,
        mother_name_en: null,
        mother_name_bn: null,
        spouse_name_en: null,
        spouse_name_bn: null,
        identification_mark: null,
        image: null,
        signature: null,
        nationality: null,
        gender_id: null,
        education_status: null,
        profession: null,
        religion: null,
        division_id: null,
        district_id: null,
        upazila: null,
        post_code: null,
        address: null,
        location_type: null,
        sub_location_type: null,
        thana_id: null,
        union_id: null,
        pouro_id: null,
        city_id: null,
        city_thana_id: null,
        district_pouro_id: null,
        ward_id_city: null,
        ward_id_upazila_union: null,
        ward_id_upazila_pouro: null,
        ward_id_dist: null,
        mobile: null,
        permanent_division_id: null,
        permanent_district_id: null,
        permanent_upazila: null,
        permanent_post_code: null,
        permanent_address: null,
        permanent_location_type: null,
        permanent_sub_location_type: null,
        permanent_thana_id: null,
        permanent_union_id: null,
        permanent_pouro_id: null,
        permanent_city_id: null,
        permanent_city_thana_id: null,
        permanent_district_pouro_id: null,
        permanent_ward_id_city: null,
        permanent_ward_id_union: null,
        permanent_ward_id_pouro: null,
        permanent_ward_id_dist: null,
        permanent_mobile: null,
        mfs_name: null,
        bank_name: null,
        nominee_en: null,
        nominee_bn: null,
        nominee_verification_number: null,
        nominee_date_of_birth: null,
        nominee_address: null,
        nominee_image: null,
        nominee_signature: null,
        nominee_relation_with_beneficiary: null,
        nominee_nationality: null,
        account_name: null,
        account_owner: null,
        account_number: null,
        application_allowance_values: [],

        application_pmt: [],
        marital_status: null,
        email: null,
        mobile_operator: null,
        pmt_status: null
      },
      professionType: [ ],
      checkbox: false,
      checkboxNomineeAddress: false,
      checkboxNomineeOptional: false,
      imageUrl: null,
      signUrl: null,
      nomineeImageUrl: null,
      programName: null,
      nomineeSignUrl: null,
      programDetails: null,
      PMTVariables: [],
      //Date of Birth
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      selectedDayNominee: null,
      selectedMonthNominee: null,
      selectedYearNominee: null,
      twoDigitDays: Array.from({ length: 32 }, (_, i) => String(i).padStart(2, '0')).slice(1),

      coverage_area: [],
      natinality: null,
      months: [

        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      years: Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i),

    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    FooterBar,
    Captcha,
    Datepicker,
    LocaleSwitcher,
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    formattedDate(newDate) {
      this.$emit("update:model", newDate);
    },



  },
  computed: {
    translatedAge() {
      if (this.data.verification_type === 2) {
        return ""; // No value when verification_type is 2
      } else {
        return this.language === "bn"
          ? this.englishToBangla(this.data.age)
          : this.data.age
            ? this.data.age.toString()
            : "";
      }
    },
    translatedNationality() {
      return this.language === "bn"
        ? this.nationality?.[0]?.value_bn
        : this.nationality?.[0]?.value_en;
      // return this.language === "bn" ? "বাংলাদেশী" : "Bangladeshi";
    },
    translatedNomineeNationality() {
      return this.language === "bn"
        ? this.nationality?.[0]?.value_bn
        : this.nationality?.[0]?.value_en;
      // return this.language === "bn" ? "বাংলাদেশী" : "Bangladeshi";
    },
    language: {
      get() {
        return this.$store.getters.getAppLanguage;
      },
    },

    formattedDate() {
      if (this.selectedDay && this.selectedMonth && this.selectedYear) {
        const monthIndex = this.months.indexOf(this.selectedMonth) + 1;
        const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
        return `${this.selectedYear}-${formattedMonth}-${this.selectedDay}`;
      }
      return null;
    },
    formattedDateNominee() {
      if (this.selectedDayNominee && this.selectedMonthNominee && this.selectedYearNominee) {
        const monthIndex = this.months.indexOf(this.selectedMonthNominee) + 1;
        const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
        return `${this.selectedYearNominee}-${formattedMonth}-${this.selectedDayNominee}`;

      }
      return null;
    },

  },

  methods: {
    englishToBangla(number) {
      const banglaDigits = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
      return number.toString().replace(/[0-9]/g, digit => banglaDigits[digit]);
    },
    onNidCaptchaToken(token){
      console.log(token);
      this.nidCaptchaToken = token;
    },

    onNomineeNidCaptchaToken(token){
      this.nomineeNidCaptchaToken = token;
    },
    
    onNidCaptchaValue(value){
      console.log(value);
      this.nidCaptchaValue = value;
    },

    onNomineeNidCaptchaValue(value){
      this.nomineeNidCaptchaValue = value;
    },

    getErrorMessage(fields) {
      return this.language == 'bn'
        ? `অনুগ্রহ পূর্বক ${fields.name_bn} প্রদান করুন`
        : `Please enter ${fields.name_en}`;
    },

    onChange($event) {
      this.data.per_room_score = (this.data.house_size / $event) * -0.05;
      this.data.per_room_score = parseFloat(this.data.per_room_score.toFixed(3));
      this.data.no_of_people_score = (this.data.house_size / $event) * this.data.per_room_score
      this.data.no_of_people_score = parseFloat(this.data.no_of_people_score.toFixed(3));
      // Do something with the input value


    },
    changeBank(selectedBankName) {

      // Find the selected bank object from the bank_names array
      // const selectedBank = this.bank_names.find(bank => bank.name_en == selectedBankName || bank.name_bn == selectedBankName);
      const selectedBank = this.banks.find(bank => bank.name_en == selectedBankName || bank.name_bn == selectedBankName);


      // Set the bank_branch_name to the corresponding bank_branch_name
      if (selectedBank) {
        this.data.branch_name = selectedBank.bank_branch_name;
      } else {
        this.data.branch_name = null; // Clear the field if no bank is selected
      }
      this.data.mfs_name = null;
    },
    changeMfs() {
      this.data.bank_name = null;

    },
    onChangeHouse($event, selected_value) {
      if (selected_value.id == 1) {
        const childWithId210 = selected_value.children.find(child => child.id == $event);

        // Get the name_en property if the child exists
        const name_en = childWithId210 ? childWithId210.name_en : null;
        this.data.house_size = name_en
      }
      if (this.data.house_size) {
        this.data.per_room_score = (this.data.house_size / this.data.no_of_room) * -0.05;
        this.data.per_room_score = parseFloat(this.data.per_room_score.toFixed(3));
        this.data.no_of_people_score = (this.data.house_size / this.data.no_of_room) * this.data.per_room_score
        this.data.no_of_people_score = parseFloat(this.data.no_of_people_score.toFixed(3));

      }
      // Do something with the input value
    },

    //User Activity Log
    async SendActivityLog() {
      const queryParams = {
        info: "Online Application",
      };
      this.$axios
        .get("/activity-log/get-information", {
          params: queryParams,
        })
        .then((result) => {

        });
    },

    checkLengthAndVerify() {
      if (this.data.nominee_verification_number.length == 10 || this.data.nominee_verification_number.length == 17) {
        if (this.data.verification_number == this.data.nominee_verification_number) {
          this.data.nominee_verification_number = null;
          this.$toast.error("Nominee cannot be the same as the applicant");
        }
      }
    },

    scrollToVerifyButton() {
      const verifyButton = document.getElementById('verify-button');
      if (verifyButton) {
        verifyButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToImage() {
      const verifyButton = document.getElementById('image');
      if (verifyButton) {
        verifyButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToSignature() {
      const verifyButton = document.getElementById('signature');
      if (verifyButton) {
        verifyButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToNomineeImage() {
      const verifyButton = document.getElementById('nominee_image');
      if (verifyButton) {
        verifyButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToNomineeSignature() {
      const verifyButton = document.getElementById('nominee_signature');
      if (verifyButton) {
        verifyButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    scrollToAccountType() {
      const verifyButton = document.getElementById('account_type');
      if (verifyButton) {
        verifyButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    getItemTextYear(item) {
      return this.language == 'bn' ? this.$helpers.englishToBangla(item.years) : item.years;

    },

    getItemText(item) {
      return this.language == 'bn' ? item.name_bn : item.name_en;
    },
    getItemTextEducationalStatus(item) {
      return this.language == 'bn' ? item.value_bn : item.value_en;
    },
    getItemValue(item) {
      return this.language == 'bn' ? item.value_bn : item.value_en;
    },

    getItemDivision(item) {
      return this.language == 'bn' ? item.name_bn : item.name_en;
    },
    getItemDistrict(item) {
      return this.language == 'bn' ? item.name_bn : item.name_en;
    },
    getItemLocation(item) {
      return this.language == 'bn' ? item.value_bn : item.value_bn;
    },
    //   getItemText(item) {
    //   return this.language == 'bn' ? item.name_bn : item.name_en;
    // },
    //   getItemText(item) {
    //   return this.language == 'bn' ? item.name_bn : item.name_en;
    // },
    //   getItemText(item) {
    //   return this.language == 'bn' ? item.name_bn : item.name_en;
    // },
    //   getItemText(item) {
    //   return this.language == 'bn' ? item.name_bn : item.name_en;
    // },
    //   getItemText(item) {
    //   return this.language == 'bn' ? item.name_bn : item.name_en;
    // },
    // mobile(){
    //   if(this.data.mobile){
    //     this.data.account_number = this.data.mobile
    //   }


    // },
    gotocheck() {
      this.$axios.get("/global/online-application/check", {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
        .then((res) => {


        })
        .catch((err) => {
        })
      // triggers watch and emits the updated date
    },
    // Update the formatted date when any dropdown changes
    updateDate() {
      this.data.date_of_birth = this.formattedDate; // triggers watch and emits the updated date
    },
    updateDateNominee() {
      this.data.nominee_date_of_birth = this.formattedDateNominee; // triggers watch and emits the updated date
    },
    resetForm() {
      this.data.location_type = null;
      this.data.program_id = null;
      this.data.verification_type = null;
      this.data.nominee_verification_type = null;
      this.data.verification_number = null;
      this.data.age = null;
      this.data.date_of_birth = null;
      this.data.name_en = null;
      this.data.name_bn = null;
      this.data.father_name_en = null;
      this.data.father_name_bn = null;
      this.data.mother_name_en = null;
      this.data.mother_name_bn = null;
      this.data.spouse_name_en = null;
      this.data.spouse_name_bn = null;
      this.data.identification_mark = null;
      this.data.image = null;
      this.data.signature = null;
      this.data.nationality = null;
      this.data.gender_id = null;
      this.data.education_status = null;
      this.data.profession = null;
      this.data.religion = null;
      this.data.division_id = null;
      this.data.district_id = null;
      this.data.upazila = null;
      this.data.post_code = null;
      this.data.address = null;
      this.data.location_type = null;
      this.data.thana_id = null;
      this.data.union_id = null;
      this.data.city_id = null;
      this.data.city_thana_id = null;
      this.data.district_pouro_id = null;
      this.data.mobile = null;
      this.data.permanent_division_id = null;
      this.data.permanent_district_id = null;
      this.data.permanent_upazila = null;
      this.data.permanent_post_code = null;
      this.data.permanent_address = '';
      this.data.permanent_location_type = null;
      this.data.permanent_thana_id = null;
      this.data.permanent_union_id = null;
      this.data.permanent_city_id = null;
      this.data.permanent_city_thana_id = null;
      this.data.permanent_district_pouro_id = null;
      this.data.permanent_mobile = null;
      this.data.nominee_en = null;
      this.data.nominee_bn = null;
      this.data.nominee_verification_number = null;
      this.data.nominee_address = null;
      this.data.nominee_image = null;
      this.data.nominee_signature = null;
      this.data.nominee_relation_with_beneficiary = null;
      this.data.nominee_nationality = null;
      this.data.account_name = null;
      this.data.account_owner = null;
      this.data.account_number = null;
      this.data.application_allowance_values = null;
      this.data.application_pmt = null;
      this.data.marital_status = null;
      this.data.email = null;
      this.data.injury_type = null;
      this.data.deadOptional = null;
    },
    checkNum() {
      // this.$refs.observer.validate();
    },
    checkIsHaveDIS() {
      if (this.programDetails != null) {
        let check = this.programDetails.additional_field.filter((item) => {
          return item.id == 11;
        })
        if (check.length == 0) {
          return false
        } else {
          return true
        }
      }

    },

    keyGetByName(name) {
      if (this.programDetails != null) {
        let key = this.programDetails.additional_field.findIndex(field => field.name_en == name);
        console.log(key, 'keyyyyy');
        return key
      }
    },
    handleNomineeCheckboxChangeOptional(event) {
      if (event == true) {
        this.data.is_nominnee_optional = 1;
      } else {
        this.data.is_nominnee_optional = 0;
      }
    },
    handleDeadChangeOption(event) {
      if (event == true) {
        this.data.dead_option_enable_disable = 1;
      } else {
        this.data.dead_option_enable_disable = 0;
      }
    },
    handleNomineeCheckboxChange(event) {
      if (event == true) {
        if (this.data.permanent_location_type == 3) {
          // if( this.data.permanent_division_id ==null && this.data.permanent_division_id ==null &&  this.data.permanent_city_id == null && this.data.permanent_city_thana_id == null && this.data.permanent_ward_id_city == null){
          //   deleteDialog=true;


          // }
          // else{ 
          const selectedDivisionObj = this.permanent_divisions.find(div => div.id == this.data.permanent_division_id);
          const selectedDistrictObj = this.permanent_districts.find(dis => dis.id == this.data.permanent_district_id);
          const selectedCityObj = this.permanent_cities.find(city => city.id == this.data.permanent_city_id);
          const selectedThanaObj = this.permanent_city_thanas.find(thana => thana.id == this.data.permanent_city_thana_id);
          const selectedWardsCityObj = this.permanent_wards_city.find(ward_city => ward_city.id == this.data.permanent_ward_id_city);

          // this.data.nominee_address = 'Division: ' + (selectedDivisionObj ? selectedDivisionObj.name_en : '') + ',' + ' District: ' + (selectedDistrictObj ? selectedDistrictObj.name_en : '') + ',' + ' City: ' + (selectedCityObj ? selectedCityObj.name_en : '') + ',' + ' Thana: ' + (selectedThanaObj ? selectedThanaObj.name_en : '') + ',' + (selectedWardsCityObj ? selectedWardsCityObj.name_en : '') + ',' + ' Post Code: ' + this.data.permanent_post_code + ',' + this.data.permanent_address;

          this.data.nominee_address = this.data.permanent_address + ',' + (selectedThanaObj ? selectedThanaObj.name_en : '') + ',' + (selectedCityObj ? selectedCityObj.name_en : '') + ',' + (selectedDistrictObj ? selectedDistrictObj.name_en : '') + '-' + this.data.permanent_post_code + ', ' + (selectedDivisionObj ? selectedDivisionObj.name_en : '');


          // }


        }
        if (this.data.permanent_location_type == 2) {
          const selectedDivisionObj = this.permanent_divisions.find(div => div.id == this.data.permanent_division_id);
          const selectedDistrictObj = this.permanent_districts.find(dis => dis.id == this.data.permanent_district_id);
          const selectedUpazilaObj = this.permanent_thanas.find(upazila => upazila.id == this.data.permanent_thana_id);
          var selectedUnionObj = this.permanent_unions.find(union => union.id == this.data.permanent_union_id);
          var selectedPouroObj = this.permanent_pouros.find(pouro => pouro.id == this.data.permanent_pouro_id);
          if (this.permanent_wards_upazila_union) {
            var selectedWardUnionObj = this.permanent_wards_upazila_union.find(ward_union => ward_union.id == this.data.permanent_ward_id_union);
          }

          if (this.permanent_wards_upazila_pouro) {
            var selectedWardPouroObj = this.permanent_wards_upazila_pouro.find(ward_pouro => ward_pouro.id == this.data.permanent_ward_id_pouro);
          }
          // var selectedWardUnionObj = this.permanent_wards_upazila_union.find(ward_union => ward_union.id == this.data.permanent_ward_id_union);
          // var selectedWardPouroObj = this.permanent_wards_upazila_pouro.find(ward_pouro => ward_pouro.id == this.data.permanent_ward_id_pouro);

          this.data.nominee_address = this.data.permanent_address + ',' + (selectedWardUnionObj ? selectedWardUnionObj.name_en : '') + '' + (selectedWardPouroObj ? selectedWardPouroObj.name_en : '') + ',' + (selectedUnionObj ? selectedUnionObj.name_en : '') + '' + (selectedPouroObj ? selectedPouroObj.name_en : '') + ',' + (selectedUpazilaObj ? selectedUpazilaObj.name_en : '') + ',' + (selectedDistrictObj ? selectedDistrictObj.name_en : '') + '-' + this.data.permanent_post_code + ', ' + (selectedDivisionObj ? selectedDivisionObj.name_en : '');

          selectedUnionObj = '';
          selectedPouroObj = '';
          selectedWardUnionObj = '';
          selectedWardPouroObj = '';



        }
        if (this.data.permanent_location_type == 1) {
          const selectedDivisionObj = this.permanent_divisions.find(div => div.id == this.data.permanent_division_id);
          const selectedDistrictObj = this.permanent_districts.find(dis => dis.id == this.data.permanent_district_id);
          const selectedDistObj = this.permanent_district_poros.find(dist => dist.id == this.data.permanent_district_pouro_id);
          const selectedDistWardObj = this.permanent_wards_dist.find(ward_dist => ward_dist.id == this.data.permanent_ward_id_dist);
          // this.data.nominee_address = 'Division: ' + (selectedDivisionObj ? selectedDivisionObj.name_en : '') + ',' + ' District: ' + (selectedDistrictObj ? selectedDistrictObj.name_en : '') + ',' + ' District Pourashava: ' + (selectedDistObj ? selectedDistObj.name_en : '') + ',' + (selectedDistWardObj ? selectedDistWardObj.name_en : '') + ',' + ' Post Code: ' + this.data.permanent_post_code + ',' + this.data.permanent_address;

          this.data.nominee_address = this.data.permanent_address + ',' + (selectedDistWardObj ? selectedDistWardObj.name_en : '') + ',' + (selectedDistObj ? selectedDistObj.name_en : '') + ',' + (selectedDistrictObj ? selectedDistrictObj.name_en : '') + '-' + this.data.permanent_post_code + ', ' + (selectedDivisionObj ? selectedDivisionObj.name_en : '');

        }







        // this.data.nominee_address = 'Division:' + this.data.permanent_division_id + 'District:'+ this.data.permanent_district_id;
        // this.data.nominee_address=this.data.permanent_address;
        //  this.data.nominee_address = 'Division: ' + (selectedDivisionObj ? selectedDivisionObj.name_en : '') + ' District: ' + this.data.permanent_district_id;
      } else {
        this.data.nominee_address = null;

      }
    },
    handleCheckboxChange(event) {
      if (event) {
        this.data.permanent_post_code = this.data.post_code
        this.data.permanent_address = this.data.address
        this.data.permanent_division_id = this.data.division_id
        this.permanent_onChangeDivision(this.data.division_id)
        this.data.permanent_district_id = this.data.district_id
        this.permanent_onChangeDistrict(this.data.district_id);
        this.data.permanent_location_type = this.data.location_type

        this.permanent_LocationType(this.data.location_type);
        this.data.permanent_union_id = this.data.union_id
        this.data.permanent_pouro_id = this.data.pouro_id

        this.data.permanent_ward_id_pouro = this.data.ward_id_pouro ?? null

        if (this.data.thana_id) {
          this.data.permanent_thana_id = this.data.thana_id
          this.data.permanent_sub_location_type = this.data.sub_location_type
          this.onChangeSubLocationTypePermanent(this.data.sub_location_type)




        }
        if (this.data.city_id) {
          this.data.permanent_city_id = this.data.city_id
          this.permanent_onChangeCity(this.data.permanent_city_id)
          this.data.permanent_city_thana_id = this.data.city_thana_id
          this.Permanent_OnChangeCityThana(this.data.permanent_city_thana_id);
          this.data.permanent_ward_id_city = this.data.ward_id_city
        }
        if (this.data.district_pouro_id) {
          this.data.permanent_district_pouro_id = this.data.district_pouro_id
          this.Permanent_onChangeDistrictPouro(this.data.permanent_district_pouro_id)

          this.data.permanent_ward_id_dist = this.data.ward_id_dist
        }

        if (this.data.union_id) {
          this.permanent_onChangeUnion(this.data.union_id);
          setTimeout(() => {
            this.data.permanent_ward_id_union = this.data.ward_id_union ?? null
          }, 4000);




        }
        if (this.data.pouro_id) {

          this.Permanent_onChangePouro(this.data.permanent_pouro_id);

          this.data.permanent_ward_id_pouro = this.data.ward_id_pouro ?? null

        }
      }
      else {
        this.data.permanent_division_id = null
        this.data.permanent_district_id = null
        this.data.permanent_upazila = null
        this.data.permanent_post_code = null
        this.data.permanent_address = null
        this.data.permanent_location_type = null
        this.data.permanent_thana_id = null
        this.data.permanent_union_id = null
        this.data.permanent_city_id = null
        this.data.permanent_city_thana_id = null
        this.data.permanent_district_pouro_id = null

      }
    },
    verifyCard() {

      let data = {
        program_id: this.data.program_id,
        gender_id: this.data.gender_id,
        verification_type: this.data.verification_type,
        verification_number: this.data.verification_number,
        date_of_birth: this.data.date_of_birth,
        captcha_token: this.nidCaptchaToken,
        captcha_value: this.nidCaptchaValue
      };

      this.$axios.post("/global/online-application/card-verification", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.nidVerified = true;
          console.log(res, 'redddddddddddddddddddddddddd');
          this.status_code = res.status;
          this.language == 'en' ? this.$toast.success(res?.data?.message) : this.$toast.success("এনআইডি তথ্য যাচাই সফলভাবে হয়েছে");
          this.data.age = Math.floor(res.data.data.age);
          this.data.name_en = res.data.data.nameEn;
          this.data.name_bn = res.data.data.name;
          this.data.account_name = res.data.data.nameEn;
          if(res.data.data.gender == 'male'){
            this.data.gender_id = 23;
          }else if(res.data.data.gender == 'female'){
            this.data.gender_id = 24;
          }else{
            this.data.gender_id = 95;
          }
          // this.$refs.nidCaptchaRef.refreshCaptcha();
        })
        .catch((err) => {
          this.status_code = null;
          if (err?.response?.data?.errors && err?.response?.data?.errors?.verification_number) {
            const verificationErrors = err.response.data.errors.verification_number;
            const beneficiaryMessageIndex = verificationErrors.indexOf('You are already a beneficiary');
            if (beneficiaryMessageIndex !== -1) {
              this.language == 'en' ? this.$toast.error('You are already a beneficiary') : this.$toast.error("আপনি ইতিমধ্যে একটি উপকারভোগী");
            } else {
              // If "You are already a beneficiary" message not found, display the first error message
              // this.$toast.error(verificationErrors[0]);
              this.language == 'en' ? this.$toast.error(err.response.data.message) : this.$toast.error("আপনি ইতিমধ্যে এনআইডি দিয়ে এপ্লিকেশন করেছেন");
            }
          } else if (err.response.data.message) {
            this.$toast.error(err.response.data.message);
          } else {
            this.$toast.error(err.response.data.message || err.response.data.error_code || 'Unknown error');
          }

          this.$refs.nidCaptchaRef.refreshCaptcha();

        })

    },

    verifyCardDob() {
      this.status_code = 200;
    },

    verifyNomineeCard() {
      this.status_code_nominee = null;
      let data = {
        nominee_verification_type: this.data.nominee_verification_type,
        verification_number: this.data.nominee_verification_number,
        date_of_birth: this.data.nominee_date_of_birth,
        captcha_token: this.nomineeNidCaptchaToken,
        captcha_value: this.nomineeNidCaptchaValue
      };

      this.$axios.post("/global/online-application/nominee-card-verification", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.nomineeNidVerified = true;
          this.status_code_nominee = res.status;
          this.$toast.success(res.data.message);

          this.data.nominee_en = res.data.data.nameEn
          this.data.nominee_bn = res.data.data.name;
          // this.$refs.nomineeNidCaptchaRef.refreshCaptcha();
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
          this.$refs.nomineeNidCaptchaRef.refreshCaptcha();
        })
    },
    verifyDISCard() {
      let data = {
        dis_no: this.data.application_allowance_values[`${this.keyGetByName('DIS No.')}`].value,
      };

      this.$axios.post("/global/online-application/dis-card-verification", data, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.$toast.success(res.data.data);

        })
        .catch((err) => {
          this.$toast.error(err.response.data.message);
        })
    },
    async submitApplicationCheck() {
      if (this.status_code != 200) {

        this.language == 'en' ? this.$toast.error("Verify First") : this.$toast.error("প্রথমে  যাচাই সম্পূর্ণ করুন");
        return false;
      }
      // if (this.data.image == null || this.data.image == '') {
      //   this.language == 'en' ? this.$toast.error("Image is required") : this.$toast.error("ছবি আবশ্যক");
      //   this.scrollToImage();
      //   return false;
      // }
      // if (this.data.signature == null || this.data.signature == '') {
      //   this.language == 'en' ? this.$toast.error("Signature is required") : this.$toast.error("স্বাক্ষর আবশ্যক");
      //   this.scrollToSignature();
      //   return false;
      // }
      if(this.programDetails?.dead_option_enable_disable == 1){
       if (this.data.dead_option_enable_disable == null) {
          this.language == 'en' ? this.$toast.error("Please Select Dead or Injured Option") : this.$toast.error("মৃত বা আহত নির্বাচন করুন");
          // this.scrollToSignature();
          return false;
        }
      }
   
      if (this.data.is_nominnee_optional == 1 && this.data.nominee_verification_type == 1) {
        if (this.status_code_nominee != 200) {
          this.language == 'en' ? this.$toast.error("Nominee Verify First") : this.$toast.error("প্রথমে  নমিনি যাচাই সম্পূর্ণ করুন");
          this.scrollToVerifyButton();
          return false;
        }
        // if (this.data.nominee_image == null || this.data.nominee_image == '') {
        //   this.language == 'en' ? this.$toast.error(" Nominee Image is required") : this.$toast.error("নমিনীর ছবি আবশ্যক");
        //   this.scrollToNomineeImage();
        //   return false;
        // }
        // if (this.data.nominee_signature == null || this.data.nominee_signature == '') {
        //   this.language == 'en' ? this.$toast.error("Nominee Signature is required") : this.$toast.error("নমিনীর স্বাক্ষর আবশ্যক");
        //   this.scrollToNomineeSignature();
        //   return false;
        // }
      }




      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        // Find the first element with an error and scroll to it
        const firstErrorElement = document.querySelector('.v-messages.error--text');
        if (firstErrorElement) {
          const windowHeight = window.innerHeight;
          const elementTop = firstErrorElement.getBoundingClientRect().top;
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const offset = elementTop + scrollTop - windowHeight / 2; // Adjust scroll position

          window.scrollTo({
            top: offset,
            behavior: 'smooth'
          });
          if (this.data.account_type == null && this.data.is_bank_mfs_mandatory == 1 ) {
            this.language == 'en' ? this.$toast.error("Select Account Type and Enter Related Data") : this.$toast.error("অ্যাকাউন্টের ধরন নির্বাচন করুন এবং সম্পর্কিত ডেটা প্রদান করুন");
            this.scrollToAccountType();
            return false;
          }
        }


      } else {
        this.confirmDialog = true;
      }
    }
    ,


    submitApplication() {

      this.data.nationality = this.nationality?.[0]?.id;
      if(this.data.is_nominnee_optional == 1){
        this.data.nominee_nationality = this.nationality?.[0]?.id;
      } else {
        this.data.nominee_nationality = null;
      }
      if (this.data.mobile_operator !== null && this.data.mobile_operator !== undefined) {
        // Convert this.data.account_number to string before concatenation
        this.data.account_number = this.data.mobile_operator + String(this.data.account_number);
      }
      this.confirmDialog = false






      let fd = new FormData();
      for (const [key, value] of Object.entries(this.data)) {
        if (value !== null) {
          if (key == 'application_allowance_values') {
            let updatedAllowanceValues = value.map(item => {
              return {
                ...item,
                deadOptional: this.deadOptional, // assuming deadOptional is available in `this`
                injury_type: this.injury_type // assuming injury_type is available in `this`
              };
            });
            fd.append("application_allowance_values", JSON.stringify(updatedAllowanceValues));

          }
          if (key == 'application_pmt') {
            fd.append("application_pmt", JSON.stringify(value));

          }
          if (key != 'application_pmt' && key != 'application_allowance_values') {
            fd.append(key, value);
          }


        }
      }
       // Debugging the FormData content
      for (let pair of fd.entries()) {
        console.log('teast anwarrr');
        console.log(pair[0], pair[1]); // Logs key and value of each FormData entry
      }
      this.loading = true;
      this.$axios.post("/global/online-application/registration", fd, {
        headers: {
          "Application": "application/json",
          "Content-Type": "multipart/form-data",
        }
      }).then((res) => {
           this.$toast.success(
          this.language == "bn"
            ? "আপনার আবেদন সফলভাবে জমা দেওয়া হয়েছে"
            : "Your Application submitted Successfully"
        );
        this.$refs.form.reset();
        this.loading = false;
        // this.$store.commit(
        //   "ApplicationSelection/setSuccessId",
        //   res.data.application_id
        // );
        this.$store.commit('ApplicationSelection/setSuccessId', res.data.application_id);

        this.$router.push("/submitted-application");

      })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.data.success == false) {
              if (err.response.data.error_code == "applicant_marital_status") {
                let errs = {
                  "marital_status": [err.response.data.message]
                }
                this.$refs.form.setErrors(errs);
                this.$toast.error(err.response.data.message);
                const firstErrorField = document.querySelector(".validation-error_marital");
                if (firstErrorField) {
                  firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                  });
                }

              }
              else if (err.response.data.error_code == "applicant_gender_type") {
                let errs = {
                  "gender_id": [err.response.data.message]
                }
                this.$refs.form.setErrors(err.response.data.message);
                //scroll to first error filed
                const firstErrorField = document.querySelector(".validation_error_gender_type");
                if (firstErrorField) {
                  firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }

                // end of scroll to first error filed
                this.$toast.error(err.response.data.message);



              }
              else if (err.response.data.error_code == "applicant_age_limit") {
                this.$refs.form.setErrors(err.response.data.message);
                //scroll to first error filed
                const firstErrorField = document.querySelector(".validation_error_age_limit");
                if (firstErrorField) {
                  firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }

                // end of scroll to first error filed
                this.$toast.error(err.response.data.message);

              }
              else if (err.response.data.error_code == "invalid_nid") {
                this.$refs.form.setErrors(err.response.data.message);
                //scroll to first error filed
                const firstErrorField = document.querySelector(".validation_error_age_limit");
                if (firstErrorField) {
                  firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }

                // end of scroll to first error filed
                this.$toast.error(err.response.data.message);

              }
              else if (err.response.data.error_code == "invalid_nominee_nid") {
                this.$refs.form.setErrors(err.response.data.message);
                //scroll to first error filed
                const firstErrorField = document.querySelector(".validation_error_nominee");
                if (firstErrorField) {
                  firstErrorField.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }

                // end of scroll to first error filed
                this.$toast.error(err.response.data.message);

              }
            } else if (err.response) {
              this.$refs.form.setErrors(err.response.data.errors);

              // this.$toast.error(err.response.data.message);
              this.$toast.error(
                (err?.response?.data?.errors?.verification_number?.[0] ?? '') +
                '\n' +
                (err?.response?.data?.errors?.mobile?.[0] ?? '')
              );

              this.errors = err.response.data.errors


              // if (err.response.data.error_code == "verification_number") {
              if (err?.response?.data?.errors?.mobile?.[0]) {
                //scroll to first error filed
                this.$nextTick(() => {
                  const firstErrorField2 = document.querySelector(".validation-error-mobile");
                  if (firstErrorField2) {
                    firstErrorField2.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "start",
                    });
                  }
                });
                // end of scroll to first error filed

              }

              if (err?.response?.data?.errors?.verification_number?.[0]) {
                //scroll to first error filed
                this.$nextTick(() => {
                  const firstErrorField1 = document.querySelector(".validation-error");
                  if (firstErrorField1) {
                    firstErrorField1.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "start",
                    });
                  }
                });
                // end of scroll to first error filed

              }


            }
          }
        });
    },

    async getProgramName() {
      let programName = this.programs.filter(
        (item) => item.id == this.data.program_id
      );
      this.data.gender_id = null;


      if(programName.length>0){
        this.$store
        .dispatch("getGlobalLookupByType", programName[0].is_disable_class == 1? 20 : 8)
        .then((res) => (this.educationalStatus = res));

        this.$store
            .dispatch("getGlobalLookupByType", 38)
            .then((res) => (this.relations_with_bef = res));
        
        let gends = programName[0].genders;
        this.$store
        .dispatch("getGlobalLookupByType", 2)
        .then((res) => (this.genders = res.filter((g)=> gends.includes(g.id))));
        const mses = programName[0].marital_status;
        this.$store
        .dispatch("getGlobalLookupByType", 32)
        .then((res) => (this.marital_status = res.filter((m)=> mses.includes(m.id))));
      }

      // this.programName = await programName[0]?.name_en;
      this.programName = await programName[0];

      this.pmt_status = await programName[0]?.pmt_status;
      this.programDetails = await programName[0];
      console.log(this.programDetails, 'this.programDetailsthis.programDetails');
      if (this.programDetails != null) {
        this.data.application_allowance_values = [];
        console.log(this.programDetails.additional_field, 'this.programDetails.additional_field');
        await this.programDetails.additional_field.forEach((item) => {
          let obj = {
            allowance_program_additional_fields_id: item.id,
            allowance_program_additional_field_values_id: null,
            value: null,
          };
          this.data.application_allowance_values.push(obj);
        });
        // lookup
        this.lookup_allowance_values = [];
        console.log(this.programDetails.lookup, 'this.programDetails.additional_field');
        await this.programDetails.lookup.forEach((item) => {
          console.log(item, 'item value lookupppppp');
          let obj = {
            id: item.type.id,
            value_en: item.type.value_en,
            value_bn: item.type.value_bn,
          };
          this.lookup_allowance_values.push(obj);
        });

        console.log(this.lookup_allowance_values, 'valueeee lookup_allowance_values');
        console.log(this.data.application_allowance_values, 'valueeee');

      }

      if (this.PMTVariables) {
        this.data.application_pmt = []
        this.PMTVariables.forEach((item) => {
          let obj = {
            variable_id: item.id,
            sub_variables: null,
          };
          this.data.application_pmt.push(obj);
        });
      }

      // }
    },
    getAllPMT() {
      this.$axios.get("global/pmt").then((res) => {
        this.PMTVariables = res.data.data;
      });
    },
    getAllProgram() {
      this.$axios.get("global/programsForApplications").then((res) => {
        this.programs = res.data.data;
        console.log(this.programs, 'this.programsthis.programs');
      });
    },
    getAllMobileOperator() {
      this.$axios.get("global/mobile-operator").then((res) => {
        this.mobile_operators = res.data;

      });
    },
    getAllDivision() {
      this.$axios.get("global/division/get").then((res) => {
        this.divisions = res.data.data;
      });
    },
    permanent_getAllDivision() {
      this.$axios.get("global/division/get").then((res) => {
        this.permanent_divisions = res.data.data;
      });
    },
    async onChangeDivision(event) {
      await this.$axios
        .get(`/global/district/get/${event}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.districts = result.data.data;
        });
    },
    async permanent_onChangeDivision(event) {
      await this.$axios
        .get(`/global/district/get/${event}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_districts = result.data.data;
        });
    },
    async onChangeSubLocationType($event) {
      // alert(event);

      if ($event == 1) {
        await this.$axios
          .get(`/global/union/pouro/get/${this.data.thana_id}`, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
            this.pouros = result.data.data;
          });
        this.data.union_id = null;
      }
      if ($event == 2) {
        this.onChangeUpazila(this.data.thana_id);
        this.data.pouro_id = null;
      }
    },
    async onChangeSubLocationTypePermanent(event) {
      // alert(event);

      if (event == 1) {
        await this.$axios
          .get(`/global/union/pouro/get/${this.data.permanent_thana_id}`, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
            this.permanent_pouros = result.data.data;

          });
        this.data.permanent_union_id = null;
      }
      if (event == 2) {
        this.permanent_onChangeUpazila(this.data.permanent_thana_id);
        this.data.permanent_pouro_id = null;
      }
    },
    async onChangeUpazila(event) {
      await this.$axios
        .get(`/global/union/get/${this.data.thana_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.unions = result.data.data;
          // this.onChangeSubLocationType(this.data.sub_location_type)

        });
    },
    async permanent_onChangeUpazila(event) {
      await this.$axios
        .get(`/global/union/get/${this.data.permanent_thana_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_unions = result.data.data;

        });
    },
    async onChangeDistrict(event) {
      await this.$axios
        .get(`/global/thana/get/${event}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.LocationType(this.data.location_type);
          this.thanas = result.data.data;

        });
    },
    async permanent_onChangeDistrict(event) {
      await this.$axios
        .get(`/global/thana/get/${event}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_LocationType(this.data.permanent_location_type);
          this.permanent_thanas = result.data.data;
          // this.getArea_5();

        });
    },
    async onChangeThana(event) {
      await this.$axios
        .get(`/global/union/get/${event}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.unions = result.data.data;
        });
    },
    async permanent_onChangeThana(event) {
      await this.$axios
        .get(`/global/union/get/${event}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_unions = result.data.data;
        });
    },
    async onChangeCity(event) {
      await this.$axios
        .get(`/global/thana/get/city/${this.data.city_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.city_thanas = result.data.data;
        });
    },
    async permanent_onChangeCity(event) {
      await this.$axios
        .get(`/global/thana/get/city/${this.data.permanent_city_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_city_thanas = result.data.data;
          // this.permanent_wards_upazila = [];
          // this.permanent_wards_dist = [];
          // this.permanent_ward_id_dist = null;
          // this.permanent_ward_id_upazila = null;
        });
    },
    async OnChangeCityThana($event) {
      await this.$axios
        .get(`/global/ward/get/thana/${this.data.city_thana_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.wards_city = result.data.data;
          this.wards_upazila = [];
          this.wards_dist = [];
          this.ward_id_dist = null;
          this.ward_id_upazila = null;

        });

    },
    async Permanent_OnChangeCityThana($event) {

      await this.$axios
        .get(`/global/ward/get/thana/${this.data.permanent_city_thana_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_wards_city = result.data.data;
          this.permanent_wards_upazila = [];
          this.permanent_wards_dist = [];
          this.permanent_ward_id_dist = null;
          this.permanent_ward_id_upazila = null;
          this.getArea();
        });

    },
    async getArea() {
      //  alert(1);
      this.data.account_type = null;
      await this.$axios
        .get(`/global/coverage-area/${this.data.permanent_division_id}/${this.data.permanent_district_id}/${this.data.permanent_city_id}/${this.data.permanent_location_type}/${this.data.permanent_sub_location_type}/${this.data.permanent_city_thana_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.banks = result?.data.bank;
          this.mfss = result?.data.mfs;
          this.branchs = result?.data.branch;
          this.processors = result?.data.bank;
          this.mfs_names_processor = result?.data.mfs;
          if (this.mfs_names_processor && this.mfs_names_processor.length > 0) {
            this.mfs_status = 1;


          }
          else {
            this.mfs_status = 0;
          }
          this.data.mfs_name = null;
          this.mfs_names = [];
          this.mfs_names_processor?.forEach(item => {
            if (item.payment_processor) {
              this.mfs_names.push(item.payment_processor);

            }
          });

          if (this.processors && this.processors.length > 0) {
            this.bank_status = 1;


          }
          else {
            this.bank_status = 0;
          }
          this.processors_name = [];
          this.bank_names = [];
          this.data.bank_name = null;

          // Iterate over the processors array
          this.processors?.forEach(item => {
            if (item.payment_processor) {
              this.processors_name?.push(item.payment_processor);

              // Iterate over the processors_name array to find bank information
              this.processors_name?.forEach(paymentProcessor => {
                if (paymentProcessor.bank) {
                  // Add bank_branch_name property to the bank object
                  paymentProcessor.bank.bank_branch_name = paymentProcessor.bank_branch_name;

                  // Push the modified bank object into bank_names array
                  this.bank_names.push(paymentProcessor.bank);
                }
              });
            }
          });


        });

    },
    async getArea_2() {
      //  alert(2);
      this.data.account_type = null;
      await this.$axios
        .get(`/global/coverage-area/${this.data.permanent_division_id}/${this.data.permanent_district_id}/${this.data.permanent_district_pouro_id}/${this.data.permanent_location_type}/${this.data.permanent_sub_location_type}/${this.data.permanent_district_pouro_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.banks = result?.data.bank;
          this.mfss = result?.data.mfs;
          this.branchs = result?.data.branch;
          this.processors = result?.data.bank;
          this.mfs_names_processor = result?.data.mfs;
          if (this.mfs_names_processor && this.mfs_names_processor.length > 0) {
            this.mfs_status = 1;


          }
          else {
            this.mfs_status = 0;
          }
          this.data.mfs_name = null;
          this.mfs_names = [];
          this.mfs_names_processor?.forEach(item => {
            if (item.payment_processor) {
              this.mfs_names.push(item.payment_processor);

            }
          });

          if (this.processors && this.processors.length > 0) {
            this.bank_status = 1;


          }
          else {
            this.bank_status = 0;
          }
          this.processors_name = [];
          this.bank_names = [];
          this.data.bank_name = null;

          // Iterate over the processors array
          this.processors?.forEach(item => {
            if (item.payment_processor) {
              this.processors_name?.push(item.payment_processor);

              // Iterate over the processors_name array to find bank information
              this.processors_name?.forEach(paymentProcessor => {
                if (paymentProcessor.bank) {
                  // Add bank_branch_name property to the bank object
                  paymentProcessor.bank.bank_branch_name = paymentProcessor.bank_branch_name;

                  // Push the modified bank object into bank_names array
                  this.bank_names.push(paymentProcessor.bank);
                }
              });
            }
          });

        });
    },
    async getArea_3() {
      this.data.account_type = null;
      await this.$axios
        .get(`/global/coverage-area/${this.data.permanent_division_id}/${this.data.permanent_district_id}/${this.data.permanent_district_id}/${this.data.permanent_location_type}/${this.data.permanent_sub_location_type}/${this.data.permanent_union_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.banks = result?.data.bank;
          this.mfss = result?.data.mfs;
          this.branchs = result?.data.branch;
          this.processors = result?.data.bank;
          this.mfs_names_processor = result?.data.mfs;
          if (this.mfs_names_processor && this.mfs_names_processor.length > 0) {
            this.mfs_status = 1;


          }
          else {
            this.mfs_status = 0;
          }
          this.data.mfs_name = null;
          this.mfs_names = [];
          this.mfs_names_processor?.forEach(item => {
            if (item.payment_processor) {
              this.mfs_names.push(item.payment_processor);

            }
          });

          if (this.processors && this.processors.length > 0) {
            this.bank_status = 1;


          }
          else {
            this.bank_status = 0;
          }
          this.processors_name = [];
          this.bank_names = [];
          this.data.bank_name = null;

          // Iterate over the processors array
          this.processors?.forEach(item => {
            if (item.payment_processor) {
              this.processors_name?.push(item.payment_processor);

              // Iterate over the processors_name array to find bank information
              this.processors_name?.forEach(paymentProcessor => {
                if (paymentProcessor.bank) {
                  // Add bank_branch_name property to the bank object
                  paymentProcessor.bank.bank_branch_name = paymentProcessor.bank_branch_name;

                  // Push the modified bank object into bank_names array
                  this.bank_names.push(paymentProcessor.bank);
                }
              });
            }
          });


        });
    },
    async getArea_4() {
      this.data.account_type = null;
      await this.$axios
        .get(`/global/coverage-area/${this.data.permanent_division_id}/${this.data.permanent_district_id}/${this.data.permanent_thana_id}/${this.data.permanent_location_type}/${this.data.permanent_sub_location_type}/${this.data.permanent_union_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.banks = result?.data.bank;
          this.mfss = result?.data.mfs;
          this.branchs = result?.data.branch;
          this.processors = result?.data.bank;
          this.mfs_names_processor = result?.data.mfs;
          if (this.mfs_names_processor && this.mfs_names_processor.length > 0) {
            this.mfs_status = 1;


          }
          else {
            this.mfs_status = 0;
          }
          this.data.mfs_name = null;
          this.mfs_names = [];
          this.mfs_names_processor?.forEach(item => {
            if (item.payment_processor) {
              this.mfs_names.push(item.payment_processor);

            }
          });

          if (this.processors && this.processors.length > 0) {
            this.bank_status = 1;


          }
          else {
            this.bank_status = 0;
          }
          this.processors_name = [];
          this.bank_names = [];
          this.data.bank_name = null;

          // Iterate over the processors array
          this.processors?.forEach(item => {
            if (item.payment_processor) {
              this.processors_name?.push(item.payment_processor);

              // Iterate over the processors_name array to find bank information
              this.processors_name?.forEach(paymentProcessor => {
                if (paymentProcessor.bank) {
                  // Add bank_branch_name property to the bank object
                  paymentProcessor.bank.bank_branch_name = paymentProcessor.bank_branch_name;

                  // Push the modified bank object into bank_names array
                  this.bank_names.push(paymentProcessor.bank);
                }
              });
            }
          });


        });
    },
    async getArea_5() {
      this.data.account_type = null;
      await this.$axios
        .get(`/global/coverage-area/${this.data.permanent_division_id}/${this.data.permanent_district_id}/${this.data.permanent_thana_id}/${this.data.permanent_location_type}/${this.data.permanent_sub_location_type}/${this.data.permanent_pouro_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.banks = result?.data.bank;
          this.mfss = result?.data.mfs;
          this.branchs = result?.data.branch;
          this.processors = result?.data.bank;
          this.mfs_names_processor = result?.data.mfs;
          if (this.mfs_names_processor && this.mfs_names_processor.length > 0) {
            this.mfs_status = 1;


          }
          else {
            this.mfs_status = 0;
          }
          this.data.mfs_name = null;
          this.mfs_names = [];
          this.mfs_names_processor?.forEach(item => {
            if (item.payment_processor) {
              this.mfs_names.push(item.payment_processor);

            }
          });

          if (this.processors && this.processors.length > 0) {
            this.bank_status = 1;


          }
          else {
            this.bank_status = 0;
          }
          this.processors_name = [];
          this.bank_names = [];
          this.data.bank_name = null;

          // Iterate over the processors array
          this.processors?.forEach(item => {
            if (item.payment_processor) {
              this.processors_name?.push(item.payment_processor);

              // Iterate over the processors_name array to find bank information
              this.processors_name?.forEach(paymentProcessor => {
                if (paymentProcessor.bank) {
                  // Add bank_branch_name property to the bank object
                  paymentProcessor.bank.bank_branch_name = paymentProcessor.bank_branch_name;

                  // Push the modified bank object into bank_names array
                  this.bank_names.push(paymentProcessor.bank);
                }
              });
            }
          });

        });
    },


    async getBranches(bank_id) {
      this.loading = true;
      this.branches = [];
      this.data.branch_id = [];
      let response;
      try {
        response = await this.$axios.get(`global/get-branches/${bank_id}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status == "200") {
          this.branches = response.data;
          this.loading = false;
        } else {
          this.$toast.error("Something went wrong");
          this.loading = false;
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        this.loading = false;
      }
    },
    async onChangeDistrictPouro($event) {
      await this.$axios
        .get(`/global/ward/get/district_pouro/${this.data.district_pouro_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.wards_dist = result.data.data;
          this.wards_upazila_union = [];
          this.wards_upazila_pouro = [];
          this.wards_city = [];
          this.ward_id_city = null;
          this.ward_id_union = null;
        });

    },
    async Permanent_onChangeDistrictPouro($event) {
      await this.$axios
        .get(`/global/ward/get/district_pouro/${this.data.permanent_district_pouro_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          this.permanent_wards_dist = result.data.data;
          this.permanent_wards_upazila_union = [];
          this.permanent_wards_upazila_pouro = [];
          this.permanent_wards_city = [];
          this.permanent_ward_id_city = null;
          this.permanent_ward_id_union = null;
          this.getArea_2();
        });

    },

    async LocationType($event) {
      if (this.data.district_id != null && this.data.location_type != null) {
        if ($event == 2) {
          await this.$axios
            .get(`/global/thana/get/${this.data.district_id}`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              this.thanas = result.data.data;
            });
        }
        if ($event == 3) {
          await this.$axios
            .get("/global/city/get/" + this.data.district_id + "/" + $event, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              this.cities = result.data.data;
            });
        }
        if ($event == 1) {
          await this.$axios
            .get("/global/city/get/" + this.data.district_id + "/" + $event, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              this.district_poros = result.data.data;
            });
        }
      }
    },
    async permanent_LocationType($event) {
      if (this.data.permanent_district_id != null && this.data.permanent_location_type != null) {
        if ($event == 2) {
          await this.$axios
            .get(`/global/thana/get/${this.data.permanent_district_id}`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              this.permanent_thanas = result.data.data;
            });
        }
        if ($event == 3) {
          await this.$axios
            .get("/global/city/get/" + this.data.permanent_district_id + "/" + $event, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              this.permanent_cities = result.data.data;
            });
        }
        if ($event == 1) {
          await this.$axios
            .get("/global/city/get/" + this.data.permanent_district_id + "/" + $event, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              this.permanent_district_poros = result.data.data;
            });
        }
      }
    },

    save(data) {
      this.$refs.menu.save(data.date_of_birth);
    },
    // previewImage() {
    //   if (this.data.image) {

    //     const maxFileSize = 200 * 1024; // 200 KB in bytes

    //     if (this.data.image.size > maxFileSize) {
    //       // alert("file size must be 200kb")
    //       // this.confirmDialog =true;
    //       if (this.language == 'en') {
    //         this.$toast.error("File size must be under 200 KB");
    //       }
    //       else {
    //         this.$toast.error("ফাইলের আকার ২০০ কে বি এর কম হতে হবে");

    //       }// Show the alert
    //       this.data.image = '';

    //       return false;
    //     }
    //     const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']; // Allowed file types
    //     if (!allowedTypes.includes(this.data.image.type)) {
    //       this.language == 'en' ? this.$toast.error("Only PNG, JPEG, or JPG files are allowed") : this.$toast.error("শুধুমাত্র পিএনজি, জেপিইজি, অথবা জেপিজি ফাইলগুলি অনুমোদিত");
    //       this.data.image = '';
    //       return false;
    //     }
    //     // return true;




    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.imageUrl = e.target.result;
    //     };
    //     reader.readAsDataURL(this.data.image);
    //   } else {
    //     // Clear the preview if no file is selected
    //     this.imageUrl = null;
    //   }

    // },
    previewImage() {
      if (this.data.image) {

        const maxFileSize = 200 * 1024; // 200 KB in bytes
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']; // Allowed file types

        if (this.data.image.size > maxFileSize) {
          this.$toast.error(this.language === 'en'
            ? "File size must be under 200 KB"
            : "ফাইলের আকার ২০০ কে বি এর কম হতে হবে");
          this.data.image = '';
          this.imageUrl = '';
          return false;
        }

        if (!allowedTypes.includes(this.data.image.type)) {
          this.$toast.error(this.language === 'en'
            ? "Only PNG, JPEG, or JPG files are allowed"
            : "শুধুমাত্র পিএনজি, জেপিইজি, অথবা জেপিজি ফাইলগুলি অনুমোদিত");
          this.data.image = '';
          this.imageUrl = '';
          return false;
        }

        // Create a new Image object to check the dimensions and aspect ratio
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            // Define the acceptable dimensions and resolution
            const requiredWidth = 180;
            const requiredHeight = 200;

            // Check dimensions
            if (width !== requiredWidth || height !== requiredHeight) {
              this.$toast.error(this.language === 'en'
                ? `Image dimensions must be ${requiredWidth}x${requiredHeight} pixels`
                : `চিত্রের আকার ${requiredWidth}x${requiredHeight} পিক্সেল হতে হবে`);
              this.data.image = '';
              this.imageUrl = '';
              return false;
            }
            // Set the image preview if all checks pass
            this.imageUrl = e.target.result;
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(this.data.image);

      } else {
        // Clear the preview if no file is selected
        this.imageUrl = null;
      }
    },


    addPreviewFile(event, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.data.application_allowance_values[index].file_value = e.target.result;
      };
      reader.readAsDataURL(event);
    },
    previewSign() {
      if (this.data.signature) {
        const maxFileSize = 200 * 1024; // 200 KB in bytes
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']; // Allowed file types

        // Validate file size
        if (this.data.signature.size > maxFileSize) {
          this.language === 'en'
            ? this.$toast.error("File size must be under 200 KB")
            : this.$toast.error("ফাইলের আকার ২০০ কে বি এর কম হতে হবে");

          // Clear the file input and preview
          this.data.signature = '';
          this.signUrl = '';
          return false;
        }

        // Validate file type
        if (!allowedTypes.includes(this.data.signature.type)) {
          this.language === 'en'
            ? this.$toast.error("Only PNG, JPEG, or JPG files are allowed")
            : this.$toast.error("শুধুমাত্র পিএনজি, জেপিইজি, অথবা জেপিজি ফাইলগুলি অনুমোদিত");

          // Clear the file input and preview
          this.data.signature = '';
          this.signUrl = '';
          return false;
        }

        // Create a new FileReader to read the image and check its dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            // Define the required dimensions
            const requiredWidth = 180;
            const requiredHeight = 200;

            // Check dimensions
            if (width !== requiredWidth || height !== requiredHeight) {
              this.language === 'en'
                ? this.$toast.error(`Image dimensions must be ${requiredWidth}x${requiredHeight} pixels`)
                : this.$toast.error(`চিত্রের আকার ${requiredWidth}x${requiredHeight} পিক্সেল হতে হবে`);

              // Clear the file input and preview if dimensions are incorrect
              this.data.signature = '';
              this.signUrl = '';
              return false;
            }

            // If dimensions are correct, set the image preview
            this.signUrl = e.target.result;
          };

          // Set the image source to trigger the onload event and check dimensions
          img.src = e.target.result;
        };

        // Read the selected image file as a Data URL
        reader.readAsDataURL(this.data.signature);
      } else {
        // Clear the preview if no file is selected
        this.signUrl = null;
      }
    },

    previewImageNominee(event) {
      if (this.data.nominee_image) {
        const maxFileSize = 200 * 1024; // 200 KB in bytes
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']; // Allowed file types

        // Validate file size
        if (this.data.nominee_image.size > maxFileSize) {
          this.language === 'en'
            ? this.$toast.error("File size must be under 200 KB")
            : this.$toast.error("ফাইলের আকার ২০০ কে বি এর কম হতে হবে");

          // Clear the file input and preview
          this.data.nominee_image = '';
          this.nomineeImageUrl = null;
          return false;
        }

        // Validate file type
        if (!allowedTypes.includes(this.data.nominee_image.type)) {
          this.language === 'en'
            ? this.$toast.error("Only PNG, JPEG, or JPG files are allowed")
            : this.$toast.error("শুধুমাত্র পিএনজি, জেপিইজি, অথবা জেপিজি ফাইলগুলি অনুমোদিত");

          // Clear the file input and preview
          this.data.nominee_image = '';
          this.nomineeImageUrl = null;
          return false;
        }

        // Create a new FileReader to read the image and check its dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            // Check for 180px width and 200px height
            if (width !== 180 || height !== 200) {
              this.language === 'en'
                ? this.$toast.error("Image dimensions must be 180px (width) and 200px (height)")
                : this.$toast.error("চিত্রের আকার ১৮০px (প্রস্থ) এবং ২০০px (উচ্চতা) হতে হবে");

              // Clear the file input and preview if dimensions are incorrect
              this.data.nominee_image = '';
              this.nomineeImageUrl = null;
              return false;
            }

            // If dimensions are correct, set the image preview
            this.nomineeImageUrl = e.target.result;
          };

          // Set the image src to the result from FileReader
          img.src = e.target.result;
        };

        // Read the selected image file as a Data URL
        reader.readAsDataURL(this.data.nominee_image);
      } else {
        // Clear the preview if no file is selected
        this.nomineeImageUrl = null;
      }
    },

    previewSignNominee(event) {
      if (this.data.nominee_signature) {
        const maxFileSize = 200 * 1024; // 200 KB in bytes
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']; // Allowed file types

        // Validate file size
        if (this.data.nominee_signature.size > maxFileSize) {
          this.language === 'en'
            ? this.$toast.error("File size must be under 200 KB")
            : this.$toast.error("ফাইলের আকার ২০০ কে বি এর কম হতে হবে");

          // Clear the file input and preview
          this.data.nominee_signature = '';
          this.nomineeSignUrl = null;
          return false;
        }

        // Validate file type
        if (!allowedTypes.includes(this.data.nominee_signature.type)) {
          this.language === 'en'
            ? this.$toast.error("Only PNG, JPEG, or JPG files are allowed")
            : this.$toast.error("শুধুমাত্র পিএনজি, জেপিইজি, অথবা জেপিজি ফাইলগুলি অনুমোদিত");

          // Clear the file input and preview
          this.data.nominee_signature = '';
          this.nomineeSignUrl = null;
          return false;
        }

        // Create a new FileReader to read the image and check its dimensions
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            // Check for 180px width and 200px height
            if (width !== 180 || height !== 200) {
              this.language === 'en'
                ? this.$toast.error("Image dimensions must be 180px (width) and 200px (height)")
                : this.$toast.error("চিত্রের আকার ১৮০px (প্রস্থ) এবং ২০০px (উচ্চতা) হতে হবে");

              // Clear the file input and preview if dimensions are incorrect
              this.data.nominee_signature = '';
              this.nomineeSignUrl = null;
              return false;
            }

            // If dimensions are correct, set the image preview
            this.nomineeSignUrl = e.target.result;
          };

          // Set the image src to the result from FileReader
          img.src = e.target.result;
        };

        // Read the selected image file as a Data URL
        reader.readAsDataURL(this.data.nominee_signature);
      } else {
        // Clear the preview if no file is selected
        this.nomineeSignUrl = null;
      }
    },

    async onChangeUnion($event) {



      await this.$axios
        .get(`/global/ward/get/${$event}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {

          this.wards_upazila_union = result.data.data;
          this.wards_upazila_pouro = [];
          this.wards_dist = [];
          this.wards_city = [];
          this.data.ward_id_dist = null;
          this.data.ward_id_city = null;
          this.data.ward_id_pouro = null;
          // this.getArea_4();

        });

    },
    async permanent_onChangeUnion($event) {
      await this.$axios
        .get(`/global/ward/get/${$event}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {

          this.permanent_wards_upazila_union = result.data.data;
          this.permanent_wards_dist = [];
          this.permanent_wards_city = [];
          this.data.permanent_ward_id_dist = null;
          this.data.permanent_ward_id_city = null;
          this.data.permanent_ward_id_pouro = null;
          this.getArea_4();

        });

    },
    async onChangePouro($event) {

      await this.$axios
        .get(`/global/ward/get/pouro/${$event}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {

          this.wards_upazila_pouro = result.data.data;
          this.wards_upazila_union = [];
          this.wards_dist = [];
          this.wards_city = [];
          this.data.ward_id_dist = null;
          this.data.ward_id_city = null;
          this.data.ward_id_union = null;
          this.getArea_3();

        });

    },
    async Permanent_onChangePouro($event) {
      await this.$axios
        .get(`/global/ward/get/pouro/${$event}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {

          this.permanent_wards_upazila_pouro = result.data.data;
          this.permanent_wards_upazila_union = [];
          this.permanent_wards_dist = [];
          this.permanent_wards_city = [];
          this.data.permanent_ward_id_dist = null;
          this.data.permanent_ward_id_city = null;
          this.data.permanent_ward_id_union = null;
          this.getArea_5();

        });

    },
  },
  mounted() {
    if (this.$refs.fieldNameInput) {
      this.$refs.fieldNameInput.$el.focus();
    }
  },
  created() {
    this.SendActivityLog();
    this.getAllProgram();
    this.getAllDivision();
    this.permanent_getAllDivision();
    this.getProgramName();
    this.getAllPMT();
    this.$store
      .dispatch("getGlobalLookupByType", 20)
      .then((res) => (this.classes = res));
    this.$store
      .dispatch("getGlobalLookupByType", 1)
      .then((res) => (this.locationType = res));
    this.$store
      .dispatch("getGlobalLookupByType", 40)
      .then((res) => (this.injuryType = res));
    this.$store
      .dispatch("getGlobalLookupByType", 9)
      .then((res) => (this.religion = res));
    this.$store
      .dispatch("getGlobalLookupByType", 34)
      .then((res) => (this.professionType = res));
    this.$store
      .dispatch("getGlobalLookupByType", 37)
      .then((res) => (this.mobile_ownership = res));
    this.$store
      .dispatch("getGlobalLookupByType", 33)
      .then((res) => (this.nationality = res));
  },

};
</script>

<style scoped>
html.my-app,
body.my-app {
  overflow: hidden !important;
}

.centered-text {
  text-align: center !important;
}

.v-label-with-asterisk::after {
  content: " *";
  color: red;
  /* You can customize the asterisk color */
}

.align-end {
  align-self: flex-end;
}

.no-calendar-icon .v-input__icon {
  display: none;
  /* Hide the default calendar icon */
}

.disabled-text-field {
  background-color: #f5f5f5;
  color: #999;
  cursor: not-allowed;
}
.account-type-label {
  margin-right: 10px;
  font-weight: bold;
}

.required-label {
  color: red;
  margin-left: 4px;
}
</style>

<style scoped>
.bg-gray-cursor-not-allowed{
  cursor: not-allowed !important;
  background-color: #f0f0f0;
}
.bg-gray-cursor-not-allowed ::v-deep input{
  cursor: not-allowed !important;
}
/* ::v-deep .v-input--is-readonly .v-input__control,
::v-deep .v-input--is-disabled .v-input__control {
  cursor: not-allowed !important;
  background-color: #f0f0f0;
} */
/* ::v-deep input[disabled] {
  cursor: not-allowed !important;
} */
/* ::v-deep input[readonly] {
  cursor: not-allowed !important;
} */
</style>
