import {extend} from "vee-validate";

export default {
  englishToBangla(input) {
    let str = typeof input === "string" ? input : String(input);

    let banglaNumber = {
      0: "০",
      1: "১",
      2: "২",
      3: "৩",
      4: "৪",
      5: "৫",
      6: "৬",
      7: "৭",
      8: "৮",
      9: "৯",
      PM: "পিএম",
      AM: "এএম",
      null: "",
    };

    for (var x in banglaNumber) {
      str = str.replace(new RegExp(x, "g"), banglaNumber[x]);
    }
    return str;
  },
  formatDate: (date, locale) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat(locale, options).format(new Date(date));
  },
  convertToEnglishDigits(input) {
    // Mapping of Bangla digits to English digits
    const banglaToEnglishMap = {
        '০': '0',
        '১': '1',
        '২': '2',
        '৩': '3',
        '৪': '4',
        '৫': '5',
        '৬': '6',
        '৭': '7',
        '৮': '8',
        '৯': '9'
    };

    // Convert the input to English digits
    let englishNum = '';

    for (let char of input) {
        // Check if the character is a Bangla digit
        if (banglaToEnglishMap[char]) {
            englishNum += banglaToEnglishMap[char]; // Convert Bangla digit to English
        } else if (char >= '0' && char <= '9') {
            englishNum += char; // Keep English digit as is
        } else {
            englishNum += char; // Keep other characters unchanged
        }
    }

    return englishNum;
  }
};

extend('checkCaseSensitiveEmail', {
    validate: (value) => {
        // Check if the value is defined and not null
        if (value === null || value === undefined || value === '') {
            return false;
        }

        // Check if the value matches the standard email pattern
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(value);
    },
    message: "Please enter a valid email address with case sensitivity",
});

extend("checkPhoneNumber", {
    validate: (value) => {
        if (!value && value !== 0) {
            return false;
        }
        // Check if all characters are numeric and not allow special characters
        const isValid = /^01[3-9]\d{8}$/.test(value);

        // Return true if both conditions are met
        return isValid;
    },
    message: "Enter a valid number eg. 017xxxxxxxx",
});