<template>
    <v-card class="captcha-component pt-2">
      <label class="ml-2">
        {{$t('captcha.verify_captcha')}}
      </label>
      <v-card-text>
        <div class="captcha-container">
          <v-img :src="captchaImage" alt="Captcha Image" class="captcha-image" />
  
          <v-btn @click="refreshCaptcha" icon color="primary">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
  
        <v-text-field
          v-model="localCaptchaValue"
          :label="$t('captcha.answer')"
          class="mt-2"
          outlined
          dense
          clearable
        />
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    name: "Captcha",
    props: {
      captchaToken: {
        type: String,
        default: ""
      },
      captchaValue: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        captchaImage: "",        // Captcha image from server
        localCaptchaValue: this.captchaValue, // Internal model for entered value
      };
    },
    watch: {
      // Watch for changes in localCaptchaValue and emit an update to the parent
      localCaptchaValue(value) {
        this.$emit("onCaptchaValue", value);
      },
      // When captchaValue prop changes, update the local model
      captchaValue(newVal) {
        this.localCaptchaValue = newVal;
      },
      // When captchaToken prop changes, fetch the new CAPTCHA image
      captchaToken(newVal) {
        if (newVal) this.fetchCaptcha(newVal);
      }
    },
    methods: {
      async fetchCaptcha(token = this.captchaToken) {
        try {
          const response = await this.$axios.get("/captcha");
          this.captchaImage = response.data.captcha_image;
          this.$emit("onCaptchaToken", response.data.captcha_token); // Emit the custom event
        } catch (error) {
          console.error("Error fetching CAPTCHA:", error);
        }
      },
      refreshCaptcha() {
        this.fetchCaptcha();          // Fetch new captcha
        this.localCaptchaValue = "";  // Clear input
      }
    },
    mounted() {
      this.fetchCaptcha(); // Load captcha on component mount
    }
  };
  </script>
  
  <style scoped>
  .captcha-component {
    margin: auto;
  }
  .captcha-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .captcha-image {
    max-height: 50px;
    max-width: 150px;
  }
  </style>
  