<template>
  <div class="locale-switcher">
    <v-btn-toggle v-model="getSwitchLabel" @change="handleSwitchChange">
      <v-btn small :color="getSwitchLabel === 0 ? '#388E3C' : undefined" :class="{ 'white--text': getSwitchLabel === 0 }">
        BN
      </v-btn>
      <v-btn small :color="getSwitchLabel === 1 ? '#283593' : undefined" :class="{ 'white--text': getSwitchLabel === 1 }">
        EN
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import { confirmed } from 'vee-validate/dist/rules';
import { mapActions } from "vuex";

export default {
  data() {
    return {
      toggle_none: 0,
    };
  },
  // computed: {
  //   getSwitchLabel: {
  //     get() {
  //       return this.$i18n.locale === 'bn' ? 0 : 1;
  //     },
  //     set(value) {
  //       this.$i18n.locale = value === 1 ? 'en' : 'bn';
  //       this.$store.commit("setAppLanguage", value === 1 ? 'en' : 'bn');
  //     }
  //   }
  // },
   computed: {
    getSwitchLabel: {
      get() {
        return this.$i18n.locale === 'bn' ? 0 : 1;
      },
      set(value) {
        const newLocale = value === 1 ? 'en' : 'bn';
        this.loadLocaleLanguage(newLocale);
        this.$i18n.locale = newLocale;
        this.$store.commit("setAppLanguage", newLocale);
      }
    }
  },
  methods: {
    ...mapActions({
      updateLanguage: "updateLanguage",
    }),
    handleSwitchChange(newLocale) {
    },
    async loadLocaleLanguage(locale) {
       this.$axios.get(`/translations/${locale}`)
        .then(response => {
          this.$store.commit("setAppLanguage", locale);
          this.$store.commit("setTranslations", response.data);
          this.$i18n.setLocaleMessage(locale, response.data);
        })
        .catch(error => {
          console.error('Error fetching language data:', error.response ? error.response.data : error);
        });
    }
  },
  created() {
    // Load the initial locale when the component is created
    this.loadLocaleLanguage(this.$i18n.locale);
  }
};
</script>

<style>
.switch-label {
  font-size: 12px;
  color: blue;
  margin-top: 5px;
}
</style>
