<template>
<div id="app" class="loginApp" style="background-image:url('/assets/images/dss.jpg');background-size:cover">
    <div class="loginAppShadow"></div>
    <v-container class="h-100vh">

        <div class="row d-flex loginBox justify-center align-center">
            <v-card elevation="12" class="d-flex align-center col-lg-8 col-12">
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" class="loginLeft ">
                      <div class="d-flex flex-column align-center justify-content-center my-5 py-15" >
                        <div class="  text-center pt-3">
                            <v-img class="text-center " max-height="100%" max-width="80px" position="center center" src="/assets/images/logo.png"></v-img>
                            <!-- <Logo class="ml-7" /> -->
                        </div>
                        <h4 class="text-center white--text pt-7">
                            Integrated Social Protection Management Information System
                            (ISPMIS)
                        </h4>
                      </div>

                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <div class="d-flex flex-column align-center justify-center" >

                          <v-row class="my-2"></v-row>
                          <v-row class="">
<!--                            1. -->
                            <v-col>
                              <div class="card ma-auto card-style"  >
                                <div class="card-body home-box-height">
                                  <a href="/online-application" class="btn btn-primary text-center ma-auto" >
                                    <v-row >
                                      <span class="text-center ma-auto">Online Application Form</span>
                                    </v-row>
                                    <v-row class="d-flex align-center pt-7">
                                      <img class="card-img-top ma-auto " src="/assets/images/ctm-home-page/online-application-form.png" alt="Card image cap" style="width: 40%;">
                                    </v-row>
                                  </a>
                                </div>
                              </div>
                            </v-col>

<!--                            2. -->
                            <v-col>
                              <div class="card ma-auto card-style" >
                                <div class="card-body home-box-height">
                                  <a href="/grievance-entry" class="btn btn-primary text-center">
                                    <v-row>
                                      <span class="text-center ma-auto">Grievance Application</span>
                                    </v-row>
                                    <v-row class="d-flex align-center pt-7">
                                      <img class="card-img-top text-center ma-auto" src="/assets/images/ctm-home-page/grievance-application.png" alt="Card image cap" style="width: 40%;">
                                    </v-row>
                                  </a>
                                </div>
                              </div>
                            </v-col>

                          </v-row>

                          <v-row>
<!--                            3. -->
                            <v-col>
                              <div class="card ma-auto card-style" >
                                <div class="card-body home-box-height">
                                  <a href="/application-tracking" class="btn btn-primary text-center">
                                    <v-row>
                                      <span class="text-center ma-auto">Online Application Tracking</span>
                                    </v-row>
                                    <v-row class="d-flex align-center pt-7">
                                      <img class="card-img-top text-center ma-auto" src="/assets/images/ctm-home-page/online-application-tracking.png" alt="Card image cap" style="width: 40%;">
                                    </v-row>
                                  </a>
                                </div>
                              </div>
                            </v-col>

<!--                            4. -->
                            <v-col>
                              <div class="card ma-auto card-style " >
                                <div class="card-body home-box-height">
                                  <a href="/grievance-tracking" class="btn btn-primary text-center">
                                    <v-row>
                                      <span class="text-center ma-auto">Grievance Tracking</span>
                                    </v-row>
                                    <v-row class="d-flex align-center pt-7">
                                      <img class="card-img-top text-center ma-auto" src="/assets/images/ctm-home-page/grievance-tracking.png" alt="Card image cap" style="width: 40%;">
                                    </v-row>
                                  </a>
                                </div>
                              </div>
                            </v-col>

                          </v-row>

                          <div class="d-flex flex-column align-center justify-center w-100 mb-1 mt-6" >
                            <v-btn elevation="2" color="primary" block href="/login">
                              MIS User Login
                            </v-btn>
                          </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>

        </div>

            <p class="text-center white--text position-relative">
              Copyright © {{ new Date().getFullYear() }}, DSS | All Rights Reserved
            </p>

    </v-container>
</div>
</template>

<script>

import {
    extend,
    ValidationProvider,
    ValidationObserver
} from "vee-validate";
import {
    required
} from "vee-validate/dist/rules";
extend("required", required);
export default {
    name: "Home",
    title: "CTM - Home",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {

    },
    computed: {

    },



    methods: {


    },
};
</script>

<style>
html.my-app,
body.my-app {
    overflow: hidden !important;
}

.h-100vh {
    height: 100vh;
}

.loginLeft {
    background: linear-gradient(180deg, rgba(43, 48, 75, 1) 54%, rgba(63, 43, 150, 1) 134%);
}

.loginBox {
    height: 100%;
}

.loginAppShadow {
    border-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(43, 43, 43, 0.6823529411764706);
    border: none;
    border-radius: 0px;
}

.position-relative {
    position: relative;
}

.w-100 {
    width: 100%;
}

.card-style {
  width: 100%; /* Allow full width on smaller screens */
  //max-width: 72%; /* Set a maximum width for larger screens */
  border: 2px solid #9ef5de;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 1.3rem;
  margin: 0.5rem; /* Add some margin for spacing */
  /* Consider adding flexbox properties for multi-column layout on smaller screens */
  display: flex; /* Enable flexbox for potential multi-column layout */
  flex-wrap: wrap; /* Allow cards to wrap on small screens */
  justify-content: space-between; /* Distribute cards evenly */
}

@media (max-width: 479px) {
  .home-box-height{
    height: 15vh;
    //height: 20vh;
  }
}
@media (min-width: 480px) {
  .home-box-height{
    height: 17vh;
  }
}

</style>
